import React, { useRef } from "react";
import Header from "../componets/navbar";
import "../css/static-pages.css";
import Footer from "../componets/footer.jsx";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Controller, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { editHelper } from "../redux/actions/helperActions";
const YOUR_PUBLIC_KEY = "_dj5PHeFub0QdyVKm";
const YOUR_SERVICE_ID = "service_47ij41y";
const YOUR_TEMPLATE_ID = "template_goovvh6";
const Help = () => {
  const { handleSubmit, control, reset } = useForm();
  const form = useRef();
  const dispatch = useDispatch();
  //   const sendEmail = (e) => {
  //     e.preventDefault();
  //     console.log("abc");
  //     emailjs
  //       .sendForm(
  //         YOUR_SERVICE_ID,
  //         YOUR_TEMPLATE_ID,
  //         form.current,
  //         YOUR_PUBLIC_KEY
  //       )
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //   };

  const sendEmail = async (data) => {
    console.log(data);
    // Use the form data for sending the email
    emailjs
      .sendForm(
        YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        form.current,
        YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          dispatch(
            editHelper({
              globalSnackbar: {
                type: "success",
                message:
                  "Message Send successfully We will revert you back soon!",
                open: true,
              },
            })
          );
          reset({}, { errors: true });
        },
        (error) => {
          console.log(error.text);
        }
      );
    // ...
  };
  return (
    <>
      <Header />
      <body className="profile">
        <div className="line"></div>

        {/* // contain */}
        <div className="contain-body">
          <h2
            className="h3Style"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            Help & Support
          </h2>
          <div className="form-text">
            <h5 className="tell-text">TELL US ABOUT YOU</h5>
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
              <div className="form-row">
                <div className="input-data">
                  <label htmlFor="name" className="label-text">
                    Name
                  </label>
                  <Controller
                    name="user_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="text"
                        className="input-field-style"
                        placeholder="Fill your name"
                        required
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="input-data">
                  <label htmlFor="name" className="label-text">
                    Contact Number
                  </label>
                  <Controller
                    name="contact_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="text"
                        className="input-field-style"
                        placeholder="Enter your contact number"
                        required
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="input-data">
                  <label htmlFor="name" className="label-text">
                    Email ID
                  </label>
                  <Controller
                    name="user_email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="text"
                        className="input-field-style"
                        placeholder="Enter your email address"
                        required
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="review-textarea">
                  <label htmlFor="websiteUrl" className="label-text">
                    Write your message
                  </label>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <textarea
                        className="review-text"
                        rows="7"
                        cols="120"
                        placeholder="Enter your message"
                        required
                        {...field}
                      />
                    )}
                  />
                  <Stack
                    direction={"row"}
                    alignContent={"center"}
                    justifyContent={"flex-end"}
                    mt={2}
                  >
                    <button
                      type="submit"
                      className="get-in"
                      style={{
                        color: "#fff",
                        backgroundColor: "transparent",
                        outline: "0",
                        // border: 0,
                      }}
                    >
                      Get in touch
                    </button>
                  </Stack>

                  {/* <Link>
                    {" "}
                    <p className="get-in">Get in touch</p>{" "}
                  </Link> */}
                  {/* <button type="submit">send Email</button>
                  <input type="submit" value="Send" /> */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr className="footer-hr-line" />
        {/* Footer start here  */}
        <Footer />
        {/* footer ends here  */}
      </body>
    </>
  );
};

export default Help;

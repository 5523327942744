import axios from "axios";
import {
  apiCall,
  endpointList,
  getRandomInt,
  isArrayWithValues,
  isDev,
  smsApiKey,
} from "../../js/helper";
import { EDIt_HELPER } from "../reducers/helper";

const editHelper = (payload) => {
  return (dispatch) => {
    dispatch({
      type: EDIt_HELPER,
      payload,
    });
  };
};

const getAllBlogs = ({ setLoading }) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      let res = await apiCall({
        endpoint: endpointList.blogs,
      });
      if (!res?.error && isArrayWithValues(res?.data)) {
        // setBlogs(res?.data);
        dispatch({
          type: EDIt_HELPER,
          payload: {
            allBlogs: res?.data,
          },
        });
      }
    } catch (error) {
    } finally {
      setLoading && setLoading(false);
    }
  };
};

const setGlobalToast = ({ open, type = "success", message }) => {
  return (dispatch) => {
    dispatch({
      type: EDIt_HELPER,
      payload: {
        globalSnackbar: {
          open,
          type,
          message,
        },
      },
    });
  };
};

const sendOpt = ({
  phoneNumber,
  setError,
  setLoading,
  navigate,
  onSuccess,
}) => {
  return async (dispatch) => {
    let otp = getRandomInt(6);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // try {
      const res = await axios.get(
        `https://www.fast2sms.com/dev/bulkV2?authorization=${smsApiKey}&variables_values=${otp}&route=otp&numbers=${phoneNumber}`,
        config
      );
      if (res?.return) {
        navigate && navigate("/login/otp");
        dispatch({
          type: EDIt_HELPER,
          payload: {
            otpInfo: { otp, phoneNumber },
          },
        });
        onSuccess && onSuccess();
      } else {
        setError && setError(res?.message);
      }
    } catch (error) {
      if (isDev()) {
        navigate && navigate("/login/otp");
        dispatch({
          type: EDIt_HELPER,
          payload: {
            otpInfo: { otp, phoneNumber },
          },
        });
      }
      // navigate("/login/otp");
    } finally {
      setLoading && setLoading(false);
    }
  };
};

const getAllWishlist = () => {
  return async (dispatch, getState) => {
    let state = getState();
    let userId = state?.user?.user?.id;
    try {
      let res = await apiCall({
        endpoint: endpointList.wishlist,
        method: "POST",
        data: {
          userId: userId,
        },
      });
      if (!res?.error && isArrayWithValues(res?.data)) {
        dispatch({
          type: EDIt_HELPER,
          payload: {
            wishlist: res?.data,
          },
        });
      }
    } catch (error) {
    } finally {
    }
  };
};

export { editHelper, getAllBlogs, setGlobalToast, sendOpt, getAllWishlist };

import React, { useState, useEffect } from "react";
import "../css/login.css";
import Images from "../images";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Login } from "../features/auth/authAction";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { getRandomInt, isDev, smsApiKey } from "../js/helper";
import axios from "axios";
import { EDIt_HELPER } from "../redux/reducers/helper";

export default function LoginForm() {
  const { success, session_id } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   // console.log(success)

  //   // redirect user to login/OTP page if registration was successful
  //   if (success) {
  //     navigate("/login/otp");
  //   }
  //   // console.log(success)
  // }, [navigate, success]);

  const submitForm = async (data) => {
    console.log(data);
    let otp = getRandomInt(6);
    console.log(otp);
    try {
      // let { data: res } = await axios({
      //   url: "https://www.fast2sms.com/dev/bulkV2",
      //   headers: {
      //     authorization: smsApiKey,
      //     variables_values: otp,
      //     route: "otp",
      //     numbers: data?.phoneNumber,
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      //   withCredentials: false,
      // });
      const { data: res } = await axios.get(
        `https://www.fast2sms.com/dev/bulkV2?authorization=${smsApiKey}&variables_values=${otp}&route=otp&numbers=${data?.phoneNumber}`
      );
      console.log(res);
      if (res?.return) {
        navigate("/login/otp");
        dispatch({
          type: EDIt_HELPER,
          payload: {
            otpInfo: { otp, phoneNumber: data?.phoneNumber },
          },
        });
      } else {
        setError(res?.message);
      }
    } catch (error) {
      if (isDev()) {
        navigate("/login/otp");
        dispatch({
          type: EDIt_HELPER,
          payload: {
            otpInfo: { otp, phoneNumber: data?.phoneNumber },
          },
        });
      }
      setError("Internal sever error!");
      // dispatch({})
      // navigate("/login/otp");
    } finally {
      setLoading(false);
    }

    // dispatch(Login(data));

    // navigate('/login/otp');
  };
  return (
    <>
      <div className="logo-style">
        <img src={Images.Logo} alt="unknown-bazaar" height={100} width={150} />
      </div>
      <div className="center-box">
        <div className="container-form">
          <h2 className="login-title">Login</h2>
          <form className="login-form" onSubmit={handleSubmit(submitForm)}>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
                {error}
                Please check your information — <strong>check it out!</strong>
              </Alert>
            )}
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              // value={phoneNumber}
              // onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter your phone number"
              {...register("phoneNumber")}
              maxLength="10"
              autoFocus
              required
            />

            <button className="btn btn--form" type="submit" disabled={loading}>
              {loading ? <CircularProgress color="success" /> : "Send OTP"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

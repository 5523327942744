import React from "react";
import "../css/offers.css";
import "../css/category.css";
import Images from "../images";
import "../css/blog.css";
import Register from "../componets/register";
import Footer from "../componets/footer";
import Header, { category } from "../componets/navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  apiCall,
  endpointList,
  extractNumbersFromString,
  getCategoryNameById,
  imageUrl,
  isArrayWithValues,
  shortenString,
  truncateString,
} from "../js/helper";
import { useDispatch, useSelector } from "react-redux";
import { EDIt_HELPER } from "../redux/reducers/helper";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
const filters = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Kids", value: "kids" },
  { label: "Delivery Pan India", value: "deliveryPanIndia" },
  { label: "Sustainable", value: "productSustainable" },
];
export default function Category() {
  const params = useParams();
  const [heading, setHeading] = useState("");
  const [id, setId] = useState(params?.id);

  const reduxAllCollection = useSelector((state) => state?.helper?.collections);
  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageDomain = imageUrl();
  useEffect(() => {
    if (params?.id) {
      setId(params?.id?.split("_")?.[0]);
    }

    return () => {};
  }, [params]);

  const setupCollection = async () => {
    try {
      setLoading(true);
      let res = await apiCall({
        endpoint: endpointList.brandByCategory,
        method: "POST",
        data: {
          categoryId: id,
        },
      });
      if (!res?.error) {
        setCollections(res?.data);
        //data = res?.data
        dispatch({
          type: EDIt_HELPER,
          payload: {
            collections: res?.data,
          },
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let headingObject = Object.values(category)
      ?.map((o) => o?.options)
      ?.flat()
      ?.find((o) => o?.id == id);

    setHeading(headingObject?.label || "");
    setupCollection();
    return () => {};
  }, [id]);

  const onBrandClick = async (id) => {
    await apiCall({
      endpoint: "seller/updateBrandTraffic",
      method: "POST",
      data: {
        subcategoryId: id,
      },
    });
  };
  const handleChange = (event) => {
    setFilter(event.target.value);
    let value = event.target.value;
    if (event.target.value) {
      if (["male", "female", "kids"]?.includes(value)) {
        setCollections(
          reduxAllCollection?.filter((o) =>
            o?.filterCategory?.split(",")?.includes(value)
          ) || []
        );
      }
      if (value === "deliveryPanIndia")
        setCollections(
          reduxAllCollection?.filter((o) => o?.deliveryPanIndia == 1) || []
        );
      if (value === "productSustainable")
        setCollections(
          reduxAllCollection?.filter((o) => o?.productSustainable == "yes") ||
            []
        );
    } else {
      setCollections(reduxAllCollection || []);
    }
  };

  return (
    <>
      <Header />
      <body className="category-body">
        <div className="line"></div>
        <div className="category" style={{ paddingTop: "10px" }}>
          <div className="nouse" style={{ marginRight: "150px" }}></div>
          <div className="cat-child1">
            <h3 className="h3Style1" style={{ color: "#E6DCC6" }}>
              {heading}
            </h3>
          </div>
          <div className="cat-child">
            <div
              class="category-dropdown"
              style={{
                marginRight: "80px",
                paddingTop: "5px",
                width: "max-content",
              }}
            >
              {/* <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Filter
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {filters?.map((o) => {
                    let { label } = o;
                    return (
                      <a class="dropdown-item" key={label}>
                        {label}
                      </a>
                    );
                  })}
                 
                </div>
              </div> */}
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={filter}
                  onChange={handleChange}
                  size="small"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {filters?.map((o) => {
                    let { label, value } = o;
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                      // <a class="dropdown-item" key={label}>
                      //   {label}
                      // </a>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {/* product Card start here  */}
        <div className="products-category">
          <div className="products-catlog">
            {collections?.map((brand) => {
              let {
                brandName,
                id,
                category1Id,
                category2Id,
                logoImage,
                aboutBrand,
                images,
                sellerId,
                heroImage,
                deliveryPanIndia,
                productSustainable,
                responseTime,
                minmaxValue,
              } = brand;
              let showResponse = extractNumbersFromString(responseTime || "");
              let responseHave = false;
              if (showResponse?.[0] < 6 || !showResponse?.[0])
                responseHave = true;
              let minPrice = minmaxValue?.split("-")?.[0] || 0;

              return (
                <div
                  class="main-container"
                  key={id}
                  onClick={() => {
                    navigate(
                      `/profile/${sellerId}_${brandName?.replaceAll(" ", "-")}`
                    );
                    onBrandClick(sellerId);
                  }}
                >
                  <div class="zoom-image-container" sx={{}}>
                    <img
                      alt="hero"
                      src={
                        heroImage
                          ? `${imageUrl()}${heroImage || ""}`
                          : Images.PlaceholderImage
                        // Images.Footwear
                      }
                      class="main-image1"
                    />
                    <img
                      alt="logo"
                      //   src={Images.Logo}
                      src={
                        logoImage
                          ? `${imageDomain}${logoImage}`
                          : Images.PlaceholderImage
                      }
                      class="rounded-image1"
                    />
                    <div class="rectangular-image">
                      <div className="product-name">
                        <p
                          className="text-style-prod"
                          style={{ alignItems: "center" }}
                        >
                          Brand Name :
                          <span className="brand-text">
                            {truncateString(brandName, 20)}
                            {/* himanshu haimshhu haimanshu ahimanshu{" "} */}
                          </span>
                        </p>

                        <p className="text-style-prod">
                          <p className="cat-label"> CATEGORIES: </p>
                          {getCategoryNameById(category1Id)?.label && (
                            <span className="span-class">
                              {getCategoryNameById(category1Id)?.label}
                            </span>
                          )}
                          {getCategoryNameById(category2Id)?.label && (
                            <span className="span-class">
                              {getCategoryNameById(category2Id)?.label}
                            </span>
                          )}
                        </p>
                        <p className="text-style-prod">
                          {shortenString(aboutBrand, 50)}
                        </p>
                        {/* <Typography>{aboutBrand}</Typography> */}
                      </div>
                      <hr className="prod-hr" />
                      <center>
                        <Tooltip title="Pan India Delivery" placement="top">
                          {deliveryPanIndia === "1" && (
                            <img
                              className="images-style"
                              src={Images.India}
                              alt="India"
                            />
                          )}
                        </Tooltip>

                        <Tooltip
                          title="Products are sustainable"
                          placement="top"
                        >
                          {productSustainable === "yes" && (
                            <img
                              className="images-style"
                              src={Images.Recycle}
                              alt=""
                            />
                          )}
                        </Tooltip>

                        <Tooltip title="Value for money" placement="top">
                          {Number(minPrice) <= 1000 && (
                            <img
                              className="images-style"
                              src={Images.Rupee}
                              alt=""
                            />
                          )}
                        </Tooltip>

                        <Tooltip
                          title="Prompt customer service"
                          placement="top"
                        >
                          {responseHave && (
                            <img
                              className="images-style"
                              src={Images.Comunnity}
                              alt=""
                            />
                          )}
                        </Tooltip>
                      </center>
                    </div>
                  </div>
                </div>
              );
            })}
            {loading && (
              <Box sx={{ mr: 8 }}>
                <Typography variant="h5" textAlign={"center"} color={"#fff"}>
                  Loading...
                </Typography>
              </Box>
            )}
            {!isArrayWithValues(collections) && !loading && (
              <Box sx={{ mr: 8 }}>
                <Typography variant="h5" textAlign={"center"} color={"#fff"}>
                  Stay tuned !
                </Typography>
              </Box>
            )}
            {/* <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image">
                <div className="product-name">
                  <p className="text-style-prod">Brand Name : XYZ</p>
                  <p className="text-style-prod">
                    CATEGORIES: <span className="span-class">Footwear</span>{" "}
                    <span className="span-class">Apparel</span>
                  </p>
                  <p className="text-style-prod">About the brand</p>
                </div>
                <hr className="prod-hr" />
                <center>
                  <img className="images-style" src={Images.India} alt="" />
                  <img className="images-style" src={Images.Recycle} alt="" />
                  <img className="images-style" src={Images.Rupee} alt="" />
                  <img className="images-style" src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div> */}
          </div>
        </div>
        {/* <Typography textAlign={"center"} color={"#fff"} sx={{ py: 2 }}>
          <a
            style={{ color: "#f66b12" }}
            href={`mailto:${"connect@unknownbazaar.com"}`}
            target="_blank"
            rel="noopener noreferrer"
            // href="connect@unknownbazaar.com"
          >
            connect@unknownbazaar.com
          </a>{" "}
          to the Help & support form.
        </Typography> */}
        {/* product Card ends here  */}

        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>
  );
}

import React from "react";
import "../css/static-pages.css";
import Footer from "../componets/footer.jsx";
import Header from "../componets/navbar";

const FAQ = () => {
    return (
        <>
            <Header />
            <body className="profile">
                <div className="line"></div>

                {/* // contain */}
                <div className="contain-body">
                    <h2
                        className="h3Style"
                        style={{ textAlign: "center", paddingBottom: "15px" }}
                    >
                        FAQ's
                    </h2>
                    <div className="contain-text">
                        <h6 className="question-static-text">1. What is Unknown Bazaar?</h6>
                        <p className="static-body-text">
                            Ans: Discover and support homegrown businesses with Unknown
                            Bazaar! We are a curated platform dedicated to showcasing
                            homegrown brands. Find unique and authentic products crafted by
                            local entrepreneurs. Connect with the community and make a
                            positive impact. Unknown Bazaar gives a new outlook to the
                            customers. They can easily navigate categories, search for brands,
                            and explore their profiles. Join us in celebrating the spirit of
                            entrepreneurship and shopping homegrown with our brand directory.
                        </p>
                        <h6 className="question-static-text">
                            2. Can I search for brands based on a specific category or
                            industry?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, our brand directory allows you to search for brands by
                            category or industry, making it easy for you to discover brands
                            that align with your interests.
                        </p>
                        <h6 className="question-static-text">
                            3. How can I contact a brand listed in the directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Each brand in the directory has its direct contact
                            information listed, such as instagram page id, WhatsApp number &
                            website. You can reach out to the brands directly using the
                            provided details.
                        </p>
                        <h6 className="question-static-text">
                            4. Can I save my favourite brands or create a wishlist within the
                            directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we have a Wishlist feature in our brand directory. You
                            can easily save your favourite brands and create a personalised
                            wish list within the directory. This allows you to keep track of
                            the brands you are interested in and easily access them for future
                            reference. Whether you come across a brand that catches your eye
                            or discover new brands you want to explore later, our Wishlist
                            feature ensures that you can easily save and manage your favourite
                            brands within the directory.
                        </p>
                        <h6 className="question-static-text">
                            5. Are there any social media links or profiles for the brands in
                            the directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, many of the brands listed in our directory have a social
                            media presence. You can find their social media links on their
                            brand profiles.
                        </p>
                        <h6 className="question-static-text">
                            6. Does the directory offer any exclusive deals or discounts for
                            the listed brands?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we often collaborate with brands to offer exclusive
                            deals and discounts to our directory users. Check our website
                            offer section or subscribe to our newsletter to stay updated.
                        </p>
                        <h6 className="question-static-text">
                            7. Is there a mobile app available for the brand directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we have a mobile app available for both iOS and Android
                            devices. You can download it from the respective app stores and
                            enjoy browsing brands on the go.
                        </p>
                        <h6 className="question-static-text">
                            8. How often is the brand directory updated with new brands?
                        </h6>
                        <p className="static-body-text">
                            Ans: We update the brand directory on a daily basis to ensure that
                            users have access to the latest and most diverse selection of
                            homegrown brands.
                        </p>
                        <h6 className="question-static-text">
                            9. Can I leave reviews or ratings for brands I have interacted
                            with through the directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we value user feedback. You can leave reviews and
                            ratings for brands you have experienced through our directory.
                            Your input helps other users make informed decisions.
                        </p>
                        <h6 className="question-static-text">
                            10. Does the directory have a search function to find specific
                            brands quickly?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, our brand directory has a robust search function that
                            allows you to find specific brands quickly. You can search by
                            brand name, category, or keywords.
                        </p>
                        <h6 className="question-static-text">
                            11. Can I search for brands based on their price range?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, you can filter your search based on a price range to
                            find brands that fit your budget. We offer options for various
                            price ranges to cater to different preferences.
                        </p>
                        <h6 className="question-static-text">
                            12. Can I search for specific types of homegrown brands in your
                            directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, our brand directory allows users to search for brands
                            based on various categories, industries, or product types. You can
                            easily find brands that align with your interests or preferences.
                        </p>
                        <h6 className="question-static-text">
                            13. Is there a fee or subscription required to access your brand
                            directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: No, our brand directory is completely free of cost. We
                            believe in supporting homegrown brands and making their products
                            accessible to everyone.
                        </p>
                        <h6 className="question-static-text">
                            14. Can I trust the brands listed in your directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we curate and vet the brands before listing them in our
                            directory. We aim to feature reputable and quality homegrown
                            brands, providing users with a reliable selection to choose from.
                        </p>
                        <h6 className="question-static-text">
                            15. Can I submit feedback or suggestions for improving the
                            directory?
                        </h6>
                        <p className="static-body-text">
                            Ans: Absolutely! We welcome your feedback and suggestions to
                            enhance the directory. You can contact us through our website or
                            email us at connect@unknownbazaar.com with your valuable input.
                        </p>
                        <h6 className="question-static-text">
                            16. Are there any promotions or discounts available exclusively through the directory?

                        </h6>
                        <p className="static-body-text">
                            Ans: Yes, we collaborate with brands to offer exclusive promotions and discounts available only through our brand directory. Stay tuned for exciting deals on our App highlight feature!

                        </p>
                    </div>
                  
                </div>
                <hr className="footer-hr-line" />
                {/* Footer start here  */}
                <Footer />
                {/* footer ends here  */}
            </body>
        </>
    );
};

export default FAQ;

import React from "react";
import Header from "../componets/navbar";
import "../css/static-pages.css";
import Footer from "../componets/footer.jsx";

export default function About() {
  return (
    <>
      <Header />
      <body className="profile">
        <div className="line"></div>
        <center><h2 className="h3Style" style={{ textAlign: "center", paddingBottom: "15px", paddingTop : "10px"}}>
            About us
          </h2></center>
        {/* // contain */}
        <div className="contain-body">
          <div className="contain-text">
            <p className="static-body-text">
              Unknown Bazaar is a curated platform where we celebrate the heart
              and soul of creativity of India. Our platform is dedicated to
              showcasing and supporting homegrown brands exclusively. UB
              provides a platform for those passionate individuals, and small
              businesses that pour their love and dedication into crafting
              unique and exceptional products.
            </p>
            <h3 className="heading-static-text">Our Mission</h3>
            <p className="static-body-text">
              At Unknown Bazaar, our mission is simple & precise. We aim to
              provide a platform that elevates and promotes the incredible
              talent and innovation found within our country’s local
              communities. Whilst making it easy for the user to connect with
              these talented brands without any clutter of advertisements. We
              believe that homegrown brands are the backbone of our economy,
              representing not just products, but the stories, traditions, and
              aspirations of our culture.
            </p>
            <h3 className="heading-static-text">Why Homegrown Matters</h3>
            <p className="static-body-text">
              We firmly believe in the power of supporting local businesses.
              When you choose to buy from homegrown brands, you're not just
              buying a product; you're investing in the dreams and ambitions of
              a budding entrepreneur/ artist. You're fostering economic growth,
              encouraging sustainability, and preserving the unique character of
              our country.
            </p>
            <h3 className="heading-static-text">What Sets Us Apart</h3>
            <p className="static-body-text">
              Our unwavering commitment to exclusively promote homegrown brands.
              Our team tirelessly research and curate a diverse collection of
              brands that are authentically local, crafted with passion just to
              fulfil one's all lifestyle needs. When you browse through Unknown
              Bazaar, you're not just window shopping; you're embarking on a
              journey through the creativity and craftsmanship of our country.
            </p>
            <h3 className="heading-static-text">Our Vision</h3>
            <p className="static-body-text">
              Our vision is to become the go-to destination for discovering and
              connecting with homegrown brands, bridging the gap between the
              homegrown brands and consumers. We envision a world where local
              businesses thrive, where their stories are heard, and where their
              products reach more and more consumers.
            </p>
            <h3 className="heading-static-text">Join the Change</h3>
            <p className="static-body-text">
              We would love for you to explore Unknown Bazaar and become a part
              of our vibrant community of homegrown enthusiasts. Whether you're
              a brand owner looking to showcase your products or a shopper
              seeking unique treasures, we're here to connect you with the heart
              and vibrant culture of our country.
            </p>
            <p className="span-body-text" >Together, let's celebrate, support, and uplift the incredible talent of India.</p>
          </div>

        </div>
        <hr className="footer-hr-line" />
        {/* Footer start here  */}
        <Footer />
        {/* footer ends here  */}
      </body>
    </>
  );
}

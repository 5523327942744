import React, { useEffect } from "react";
import Images from "../images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OtpVerify } from "../features/auth/authAction";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { apiCall, endpointList } from "../js/helper";
import { EDIt_USER } from "../redux/reducers/userReducers";
import RegisterPopup from "../componets/RegisterPopup";
import { sendOpt, setGlobalToast } from "../redux/actions/helperActions";

export default function OTPForm() {
  const { otpSuccess } = useSelector((state) => state.auth);
  const otpInfo = useSelector((state) => state?.helper?.otpInfo);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openPopup, setOenPopup] = useState(false);

  useEffect(() => {
    // redirect user to main page  page if registration was successful
    if (otpSuccess) {
      navigate("/");
    }
  }, [navigate, otpSuccess]);

  const submitForm = async (data) => {
    console.log(data);
    let { otp, phoneNumber } = otpInfo || {};
    if (otp != data?.otp) return setError("Please Enter valid OTP!");
    try {
      let res = await apiCall({
        endpoint: endpointList.login,
        method: "POST",
        data: {
          phoneNumber,
        },
      });
      if (!res?.error && res?.data?.isRegistered) {
        dispatch({
          type: EDIt_USER,
          payload: {
            user: res?.data?.data,
          },
        });
        navigate("/");
      } else if (!res?.data?.isRegistered) {
        setOenPopup(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
    // dispatch(OtpVerify(data));
    // navigate('/')
  };

  const handleClose = () => {
    setOenPopup(false);
    navigate("/");
  };
  const reSendOtp = () => {
    dispatch(
      sendOpt({
        phoneNumber: otpInfo?.phoneNumber,
        setError,
        setLoading,
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              open: true,
              message: "OTP Send Successfully !",
            })
          );
        },
      })
    );
  };

  return (
    <>
      <div className="logo-style">
        <img src={Images.Logo} alt="unknown-bazaar" height={100} width={150} />
      </div>
      <div className="center-box">
        <div className="container-form">
          <h2 className="login-title">Enter Valid OTP</h2>
          <center>
            <p className="otp">OTP is send on your Number : </p>
          </center>
          <form className="login-form" onSubmit={handleSubmit(submitForm)}>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {/* Please verify your otp  */}
                {error}
                <strong>check it out!</strong>
              </Alert>
            )}
            <center>
              <label htmlFor="phone">OTP</label>
            </center>
            <input
              className="input-style"
              type="text"
              id="otp"
              name="otp"
              // value={phoneNumber}
              // onChange={handlePhoneNumberChange}
              {...register("otp")}
              placeholder="Enter OTP"
              maxLength="10"
              required
            />

            <button className="btn btn--form" type="submit" disabled={loading}>
              {loading ? <CircularProgress color="success" /> : "Login"}
            </button>
          </form>
          <center className="otp-resend">
            <p>
              OTP not received <Link onClick={reSendOtp}>Resend OTP</Link>
            </p>
          </center>
        </div>
      </div>
      <RegisterPopup handleClose={handleClose} openPopup={openPopup} />
    </>
  );
}

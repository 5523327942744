import React, { useState } from "react";
import {
  apiCall,
  getToken,
  getUserId,
  isArrayWithValues,
  isDev,
} from "../js/helper";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setGlobalToast } from "../redux/actions/helperActions";

export default function Review({ product, setOenPopup, setProduct }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <AddReview
          open={open}
          handleClose={() => setOpen(false)}
          product={product}
          setProduct={setProduct}
        />
      )}
      <div className="review">
        <h4 className="review-heading">Customer Reviews</h4>
        <div
          className={`review-body ${getToken() ? "" : ""}`} // Aamir removing blurred-div - use blurred-div to hide
          // onClick={() => !token && setOenPopup && setOenPopup(true)}
          // onClick={() => !token && navigate("/login")}
        >
          {isArrayWithValues(product?.review)
            ? product?.review?.map((obj) => {
                let { id, description, userId } = obj || {};
                return (
                  <div class="box" key={id}>
                    <p className="username">{/* {userId} */}"</p>
                    <p className="reviewText">{description}</p>
                  </div>
                );
              })
            : null}

          {/* <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div>
                <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div>
                <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div>
                <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div>
                <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div>
                <div class="box">
                    <p className='username'>@XYZHJI</p>
                    <p className='reviewText'>loved the products whcih they have, comfortable and affprdable</p>
                </div> */}
        </div>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                color: "#fff",
                marginBottom: "20px",
                fontFamily: "bodyText",
              }}
              onClick={() => setOpen(true)}
            >
              Add Review
            </Button>
          </Stack>
        </Box>
      </div>
    </>
  );
}
const AddReview = ({ open, handleClose, product, setProduct }) => {
  const [reviewState, setReviewState] = useState({});
  const dispatch = useDispatch();
  const editForm = (payload) => {
    setReviewState((pre) => ({ ...pre, ...payload }));
  };
  const addReview = async () => {
    let res = await apiCall({
      endpoint: "review/addReview",
      method: "POST",
      data: {
        description: reviewState?.description,
        sellerId: product?.sellerId,
        userId: getUserId() || 1,
      },
    });
    if (res?.data) {
      setProduct((pre) => ({ ...pre, review: res?.data }));
      handleClose();
      dispatch(
        setGlobalToast({
          open: true,
          message: "Review Added successfully!",
        })
      );
    }
    console.log(res);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        style={{ fontFamily: "bodyText" }}
      >
        <DialogTitle>Add Review</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Review on this Brand !</DialogContentText>
          <TextField
            multiline
            rows={4}
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="standard"
            value={reviewState?.description}
            onChange={(e) => editForm({ description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={addReview}
            variant="contained"
            sx={{ color: "#fff" }}
          >
            Add Review
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Navigate, json } from "react-router-dom";
import Cookies from "js-cookie";
import { apiCall, endpointList, isDev } from "../../js/helper";
import { EDIt_USER } from "../../redux/reducers/userReducers";

export const Login = createAsyncThunk(
  "/login",

  async ({ phoneNumber }, { rejectWithValue, dispatch }) => {
    // if (isDev()) {

    //   return;
    // }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var phoneOTP = await axios.get(
        `https://2factor.in/API/V1/800ef114-2dfd-11ee-addf-0200cd936042/SMS/+91${phoneNumber}/AUTOGEN`
      );

      console.log(phoneOTP.data.Details);
      console.log(phoneNumber);
      Cookies.set("Phone", phoneNumber, { expires: 30 });
      // setting cookies for further otp verify step
      let Details = phoneOTP.data.Details;
      // console.log(Details)
      Cookies.set("Details", Details, { expires: 7 }); // 'expires' indicates the number of days until the cookie expires
      // let navigate = Navigate;
      // navigate('/login/otp')
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const OtpVerify = createAsyncThunk(
  // '/login/otp',

  async ({ otp }, { rejectWithValue }) => {
    try {
      var session_id = Cookies.get("Details");
      var verifyOTP = await axios.get(
        `https://2factor.in/API/V1/800ef114-2dfd-11ee-addf-0200cd936042/SMS/VERIFY/${session_id}/${otp}`
      );
      console.log(verifyOTP.data.Details);

      // setting cookies for further otp verify step
      let OTPStatus = verifyOTP.data.Details;
      Cookies.set("OTPStatus", OTPStatus, { expires: 30 }); // 'expires' indicates the number of days until the cookie expires
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

import React from "react";
import Header from "../componets/navbar";
import "../css/static-pages.css";
import Footer from "../componets/footer.jsx";

const TermCondition = () => {
  return (
    <>
      <Header />
      <body className="profile">
        <div className="line"></div>

        {/* // contain */}
        <h2
          className="h3Style"
          style={{
            textAlign: "center",
            paddingBottom: "15px",
            paddingTop: "10px",
          }}
        >
          Terms & Conditions
        </h2>
        <div className="contain-body">
          <div className="contain-text">
            <p className="static-body-text">
              We are VKG AND RS SOLUTION TECH PVT LTD ("Unknown Bazaar," "we,"
              "us," "our"). We operate the website www.unknownbazaar.com (the
              ‘Site’) the mobile application Unknown Bazaar( the ‘App’) , as
              well as any other related products and services that refer or link
              to these legal terms (the "Legal Terms") (collectively, the
              "Services").
              <br />
              You can contact us by email at{" "}
              <span style={{ color: "#f66b12" }}>
                [connect@unknownbazaar.com]
              </span>{" "}
              <br />
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ("you"),
              or VKG AND RS SOLUTION TECH PVT LTD, concerning your access to and
              use of the Services. You agree that by accessing the Services, you
              have read, understood, and agreed to be bound by all of these
              Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS,
              THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU
              MUST DISCONTINUE USE IMMEDIATELY.
              <br />
              We will provide you with prior notice of any scheduled changes to
              the Services you are using. The modified Legal Terms will become
              effective upon posting or notifying you by{" "}
              <span style={{ color: "#f66b12" }}>
                [connect@unknownbazaar.com]
              </span>
              , as stated in the email message. By continuing to use the
              Services after the effective date of any changes, you agree to be
              bound by the modified terms.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Definitions </span>
              <br />
              a. "User(s)": Individuals who access the Platform to browse
              products, access information, and interact with sellers.
              <br />
              b. "Seller(s)": Merchants, retailers, or individuals who use the
              Platform to list and sell products or services to Users.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                Account Creation and Eligibility{" "}
              </span>
              <br />
              a. User Accounts: Some features of the Platform may require Users
              to create an account. You must be at least 18 years old or of
              legal age in your jurisdiction to create an account. By creating
              an account, you agree to provide accurate and complete
              information. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account.
              <br />
              b. Seller Accounts: Sellers must register and create an account to
              list products or services on the Platform. By registering as a
              Seller, you represent that you have the authority to sell the
              listed products or services and agree to comply with our Seller
              Terms and Conditions.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> User Responsibilities</span>
              <br />
              a. Permitted Use: Users may access and use the Platform solely for
              personal, non-commercial purposes and in compliance with these
              Terms and all applicable laws and regulations.
              <br />
              b. Prohibited Activities: Users agree not to use the Platform for
              any unlawful or prohibited purposes, including but not limited to:
              <br />
              Violating any applicable laws or regulations.
              <br />
              Infringing on the intellectual property rights of others.
              <br />
              Engaging in fraudulent activities.
              <br />
              Impersonating any person or entity or misrepresenting your
              affiliation with any person or entity.
              <br /> Interfering with the proper functioning of the Platform.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Seller Responsibilities
              </span>
              <br />
              a. Product Listings: Sellers are responsible for the accuracy and
              completeness of product or service listings. All listed products
              or services must comply with applicable laws and regulations.
              <br />
              b. Pricing and Payment: Sellers are responsible for setting
              accurate prices for their products or services and for providing
              accurate payment and shipping information.
              <br />
              c. Order Fulfilment: Sellers are responsible for fulfilling orders
              promptly and providing accurate shipping and delivery information.
              <br />
              <br />
              <span>Intellectual Property </span>
              <br />
              a. All content on the Platform, including but not limited to text,
              images, graphics, logos, and trademarks, is the property of
              Unknown Bazaar or its content suppliers and is protected by
              intellectual property laws. Users and Sellers agree not to use or
              reproduce any content without the express permission of the
              respective owner.
              <br />
              b. Seller Product Images: Sellers grant Unknown Bazaar a
              non-exclusive, royalty-free, perpetual, irrevocable, and fully
              sublicensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, and display
              product images worldwide for promotional purposes on the Platform.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Privacy</span>
              <br />
              a. User Privacy: The collection and use of personal information of
              Users are governed by our User Privacy Policy. By using the
              Platform, you consent to the practices described in the User
              Privacy Policy.
              <br />
              b. Seller Privacy: The collection and use of personal information
              of Sellers are governed by our Seller Privacy Policy. By
              registering as a Seller on the Platform, you consent to the
              practices described in the Seller Privacy Policy.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                Third-Party Links and Content
              </span>
              <br />
              a. The Platform may contain links to third-party websites or
              content. Unknown Bazaar is not responsible for the content or
              practices of these third-party websites. Users and Sellers access
              third-party websites at their own risk and are encouraged to
              review the terms and privacy policies of such websites.
              <br />
              <span style={{ fontWeight: "bold" }}> Dispute Resolution </span>
              <br />
              a. Any disputes arising from or relating to the use of the
              Platform shall be governed by the laws of [Your Jurisdiction].
              Users and Sellers agree to resolve any disputes through mediation
              or arbitration, with the costs of mediation or arbitration shared
              equally between the parties.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                Limitation of Liability{" "}
              </span>
              <br />
              a. In no event shall Unknown Bazaar or its affiliates, directors,
              officers, employees, or agents be liable to Users or Sellers for
              any direct, indirect, incidental, special, or consequential
              damages arising out of or in connection with the use of the
              Platform or its content.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Modifications to the Terms
              </span>
              <br />
              a. Unknown Bazaar reserves the right to modify these Terms at any
              time. The most recent version will be posted on the Platform, and
              your continued use of the Platform after any changes will
              constitute your acceptance of the updated Terms.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Termination</span>
              <br />
              a. Unknown Bazaar reserves the right to suspend or terminate User
              or Seller accounts or access to the Platform at any time and for
              any reason, including but not limited to violations of these
              Terms.
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Entire Agreement </span>
              <br />
              a. These Terms constitute the entire agreement between Users,
              Sellers, and Unknown Bazaar regarding the use of the Platform and
              supersede any prior agreements or understandings.
              <br />
              Contact Us
              <br />
              a. If you have any questions or concerns regarding these terms and
              conditions, please contact us at{" "}
              <span style={{ color: "#f66b12" }}>
                [connect@unknownbazaar.com]
              </span>
              .
            </p>
          </div>
        </div>
        <hr className="footer-hr-line" />
        {/* Footer start here  */}
        <Footer />
        {/* footer ends here  */}
      </body>
    </>
  );
};

export default TermCondition;

import React from "react";
import Images from "../images/index.js";
import "../css/home.css";
import Register from "../componets/register.jsx";
import Footer from "../componets/footer.jsx";
import Header from "../componets/navbar.jsx";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <body style={{ background: "#070606" }} className="home">
        <div className="hero-section">
          <img className="hero-image" src={Images.Hero} alt="" />
          <img className="mobile-hero-image" src={Images.MobileHero} alt="" />
        </div>

        {/* categories start */}
        <div className="category">
          <h3 className="heading">
            <center>Categories</center>
          </h3>
        </div>
        <div className="category-conatiner">
          <div
            className="child"
            onClick={() => {
              navigate("/category/1_Clothing");
            }}
          >
            <img className="category-image" src={Images.Clothes} alt="" />
            <h4 className="category-text">
              <center>Clothing</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/2_Footwear");
            }}
          >
            <img className="category-image" src={Images.Footwear} alt="" />
            <h4 className="category-text">
              <center>Footwear</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/3_Accessories");
            }}
          >
            <img className="category-image" src={Images.Accessory} alt="" />
            <h4 className="category-text">
              <center>Accessories</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/17_Food-beverages");
            }}
          >
            <img className="category-image" src={Images.Food} alt="" />
            <h4 className="category-text">
              <center>Food & beverages</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/14_Pet-Care");
            }}
          >
            <img className="category-image" src={Images.Pet} alt="" />
            <h4 className="category-text">
              <center>Pet Care</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/18_Services");
            }}
          >
            <img className="category-image" src={Images.Services} alt="" />
            <h4 className="category-text">
              <center>Services</center>
            </h4>
          </div>
          <div
            className="child"
            onClick={() => {
              navigate("/category/21_Beauty-wellness");
            }}
          >
            <img className="category-image" src={Images.Beauty} alt="" />
            <h4 className="category-text">
              <center>Beauty & wellness</center>
            </h4>
          </div>
        </div>

        {/* categories ends */}

        {/* why UB  */}

        <div className="reason">
          <div className="category">
            <h3 className="h3Style">
              <center>Why Unknown Bazaar?</center>
            </h3>
          </div>
          <div className="intro">
            <div className="intro-child">
              <center>
                <img src={Images.UB1} alt="" height={100} width={100} />
                <p className="intro-text">
                  Avoid the clutter
                  <br /> Enjoy hassle free experience
                </p>
              </center>
            </div>
            <div className="intro-child">
              <center>
                <img src={Images.UB2} alt="" height={100} width={100} />
                <p className="intro-text">
                  Support local homegrown <br /> businesses
                </p>
              </center>
            </div>
            <div className="intro-child">
              <center>
                <img
                  className=""
                  src={Images.UB3}
                  alt=""
                  height={100}
                  width={100}
                />
                <p className="intro-text">
                  Be a part of worlds <br /> fastest growing economy
                </p>
              </center>
            </div>
          </div>
        </div>
        {/* why UB  */}

        {/* Blog Library start */}
        <div className="blog">
          <div className="category">
            <h3 className="h3Style" style={{ paddingTop: "10px" }}>
              <center>Blogs Library </center>
            </h3>
          </div>
          <div className="blog-flex">
            <center onClick={() => navigate("/blog-detail/12")}>
              <div className="blog-library">
                <img
                  className="blog-image"
                  src={Images.Blog1}
                  alt=""
                  style={{}}
                />
              </div>
            </center>
            <center onClick={() => navigate("/blog-detail/10")}>
              <div className="blog-library">
                <img
                  className="blog-image"
                  src={Images.Blog2}
                  alt="blog-images"
                />
              </div>
            </center>
          </div>
        </div>
        {/* Blog Library ends */}
        <div className="brand">
          <div className="brand-overlay"></div>
          <div className="category">
            <h3 className="h3Style">
              <center>How it works?</center>
            </h3>
          </div>
          <div className="brand-work">
            <div className="brand-child">
              <center>
                <h4 className="brand-text1">BRAND DIRECTORY</h4>
                <p className="body-text1">
                  Showcasing curated homegrown brands under one roof. <br />{" "}
                  Explore the unknown.
                </p>
                <img src={Images.Shop} alt="" height={250} />
              </center>
            </div>
            <div className="brand-child">
              <center>
                <h4 className="brand-text1">BRAND CHECK</h4>
                <p className="body-text1">
                  Verified homegrown brands based on factors such as product
                  quality, convenience of payment, brand authenticity etc. just
                  @UBazaar
                </p>
                <img src={Images.Verified} alt="" height={200} />
              </center>
            </div>
            <div className="brand-child verified-bottom">
              <center>
                <h4 className="brand-text1">HASSLE FREE EXPERIENCE</h4>
                <p className="body-text1">
                  Overwhelmed with too many irrelevant options?
                  <br /> "Find what you want when you want"@UBazaar{" "}
                </p>
                <img src={Images.Mobile} alt="" height={250} />
              </center>
            </div>
          </div>
        </div>

        {/* How its works start */}

        {/* Register your homegrown brand here! */}
        <Register />
        {/* Register your homegrown brand here! */}

        {/* Footer start here  */}
        <Footer />
        {/* footer ends here  */}
      </body>
    </>
  );
}

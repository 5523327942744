import React from "react";
import "../js/main.js";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Images from "../images/index.js";
import { IoPersonOutline } from "react-icons/io5";
import { AiOutlineHeart } from "react-icons/ai";
import "../css/navbar.css";
import { AiOutlineDown } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import Dropdown from "./dropdown.jsx";
import ReactGA from "react-ga";

import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import { apiCall, getSellerSite } from "../js/helper.js";
import { useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";

export const category = {
  fashion: {
    heading: "Fashion",
    options: [
      { label: "Clothing", id: 1 },
      { label: "Footwear", id: 2 },
      { label: "Accessories", id: 3 },
      { label: "Active wear", id: 4, isDisabled: true },
    ],
  },
  Others: {
    heading: "Others",
    options: [
      { label: "Pet Care", id: 14 },
      { label: "Food & beverage", id: 17 },
      { label: "Services", id: 18 },
      { label: "Beauty & Wellness", id: 21 },
      { label: "Baby Care", id: 15, isDisabled: true },
      { label: "Gifting / customization", id: 16, isDisabled: true },
    ],
  },
  beauty: {
    heading: "Beauty & Wellness",
    options: [
      { label: "Makeup", id: 5, isDisabled: true },
      { label: "Skincare", id: 6, isDisabled: true },
      { label: "Haircare", id: 7, isDisabled: true },
      { label: "Bath & Body", id: 8, isDisabled: true },
      { label: "Health & Wellness", id: 9, isDisabled: true },
    ],
  },
  living: {
    heading: "Home & living",
    options: [
      { label: "Furniture", id: 10, isDisabled: true },
      { label: "Home Décor", id: 11, isDisabled: true },
      { label: "Home Furnishing", id: 12, isDisabled: true },
      { label: "Bath", id: 13, isDisabled: true },
      { label: "Kitchen", id: 19, isDisabled: true },
      { label: "Dining", id: 20, isDisabled: true },
    ],
  },
};

export default function Header() {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const user = useSelector((state) => state?.user?.user);
  const onBrandClick = async (id) => {
    await apiCall({
      endpoint: "subcategory/updateSubcategoryCount",
      method: "POST",
      data: {
        subcategoryId: id,
      },
    });
  };
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const onSearch = () => {
    if (search) {
      let id = Object.values(category)
        ?.map((o) => o?.options)
        ?.flat()
        ?.find((o) =>
          o?.label
            ?.replaceAll(" ", "")
            ?.toLocaleLowerCase()
            ?.includes(search?.toLocaleLowerCase())
        )?.id;
      if (id) navigate(`/category/${id}`);
      console.log(id);
    }
  };
  const handleEnter = (event) => {
    // event.preventDefault();
    if (event.key === "Enter") {
      onSearch();
    }
  };
  return (
    <>
      <div className="topbar">
        {/* <div className="topbar-item"><Link to="/profile" className="topbar-item1"> <IoPersonOutline /> Profile</Link></div> */}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
          sx={{ p: 2 }}
        >
          <Dropdown />
          <Button
            sx={{ color: "#fff" }}
            startIcon={<FavoriteBorderOutlined />}
            onClick={() => navigate(`/wishlist`)}
          >
            Wishlist{" "}
          </Button>
        </Stack>
        {/* <div className="topbar-item">
          <Link to="/wishlist" className="topbar-item1">
            <AiOutlineHeart size={20} /> Wishlist
          </Link>
        </div> */}
      </div>

      <nav>
        <div className="wrapper">
          <div>
            <Link to="/">
              <img
                className="logo"
                src={Images.Logo}
                alt="unknown-baazar"
                style={{ objectFit: "cover" }}
                height={70}
                width={100}
              />
            </Link>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" className="btn close-btn">
              <i className="fas fa-times" />
            </label>
            <li style={{ flex: 1 }}></li>
            <li className="mega-link">
              {/* <div className="desktop-item">
                Category <AiOutlineDown size={20} />{" "}
              </div> */}
              <a
                href=""
                // onClick="event.preventDefault();"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="desktop-item"
              >
                Category <AiOutlineDown size={15} />{" "}
              </a>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item">
                Category
              </label>
              <div className="mega-box">
                <div className="dropdown-box-back">
                  <div className="content">
                    {Object.keys(category || {})?.map((key) => {
                      let { heading, options } = category?.[key] || {};
                      return (
                        <Fragment key={key}>
                          <div className="row" key={key}>
                            <header>{heading}</header>
                            <ul className="mega-links">
                              {options?.map((obj) => {
                                let { label, isDisabled, id } = obj;
                                return (
                                  <li key={id}>
                                    <Link
                                      to={`/category/${id}_${label}`}
                                      className={isDisabled ? "isDisabled" : ""}
                                      onClick={() => {
                                        ReactGA.event({
                                          category: label,
                                          action: "navigate_category",
                                          label: "category",
                                        });
                                        onBrandClick(id);
                                      }}
                                    >
                                      {label}{" "}
                                      {isDisabled && (
                                        <span
                                          style={{
                                            color: "#f66b12",
                                            fontSize: "20px",
                                          }}
                                        >
                                          *
                                        </span>
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                  <span className="span-comming">*Comming Soon</span>
                </div>
              </div>
            </li>
            {/* <li className="search-bar-style"> */}
            {/* <form
                class=" form-search form-inline my-6 my-lg-0"
                // style={{ overflow: "hidden" }}
              > */}
            {/* <div className=" form-search form-inline my-6 my-lg-0">
                <input
                  style={{
                    fontSize: "13px",
                    background: "#000",
                    color: "#fff",
                  }}
                  className=" input-style form-control mr-sm-2"
                  // type="search"
                  value={search}
                  onKeyDown={handleEnter}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search in Category here"
                  aria-label="Search"
                />
              </div> */}
            {/* <Autocomplete
                disablePortal
                id="Search"
                size="small"
                placeholder="Search in Category here"
                fullWidth
                freeSolo
                disableClearable
                value={value}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  let { value: id } = newValue || {};
                  onBrandClick(id);
                  navigate(`/category/${id}`);
                  setValue(newValue);
                }}
                // sx={{ fontSize: "13px", background: "#000", color: "#fff" }}
                options={Object.values(category)
                  ?.map((o) => o?.options)
                  ?.flat()
                  ?.map((o) => ({ label: o?.label, value: o.id }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color={"primary"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main, // Set outline color to primary color
                      },
                    }}
                    // variant="standard"
                    placeholder="Search in Category here"
                    // className="input-style form-control mr-sm-2"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      style: {
                        borderRadius: "8px",
                        borderColor: theme.palette.primary.main,
                      },
                    }}
                  />
                )}
              /> */}

            {/* <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
            {/* </form> */}
            {/* </li> */}
            <li className="link-style" style={{ paddingLeft: "10px" }}>
              <Link to="/blog">Blog Library</Link>
            </li>
            <li className="link-style">
              <Link activeClassName="active" to="/about">
                About us
              </Link>
            </li>
            <li
              className="link-style"
              onClick={() => window.open(getSellerSite(), "_blank")}
              style={{ color: "#fff" }}
            >
              <Link>Register Your Brand</Link>
            </li>
            <div class="top-texts">
              <ul class="top-texts-list">
                <li class="top-text">
                  <Link
                    to={user ? "/profile" : "/login"}
                    className="topbar-item1"
                  >
                    {" "}
                    <IoPersonOutline /> Profile
                  </Link>
                </li>
                <li class="top-text">
                  <Link to="/wishlist" className="topbar-item1">
                    <AiOutlineHeart /> Wishlist
                  </Link>
                </li>
                <li class="top-text">
                  {" "}
                  <Link to="/wishlist" className="topbar-item1">
                    <FiLogOut /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn">
            <i className="fas fa-bars" />
          </label>
        </div>
      </nav>
    </>
  );
}

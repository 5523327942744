import React from "react";
import "../src/css/main.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Apparel from "./pages/apparel";
import Footwear from "./pages/footwear";
import Accessories from "./pages/accessories";
import Food from "./pages/food";
import PetCare from "./pages/petCare";
import Service from "./pages/service";
import Beauty from "./pages/beauty";
import About from "./pages/about";
import Wishlist from "./pages/wishlist";
import Profile from "./pages/profile";
import ProductDetails from "./pages/product-details";
import Blog from "./pages/blog";
import Offers from "./pages/offers";
import BlogDetails from "./pages/blog-details";
import NotFound from "./pages/not-found";
import LoginForm from "./pages/login";
import OTPForm from "./pages/otp";
import CreateProfile from "./pages/create-profile";
import Category from "./pages/Category";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { editHelper } from "./redux/actions/helperActions";
import FAQ from "./pages/FAQ";
import TermCondition from "./pages/term-condition";
import Privacy from "./pages/privacy";
import Help from "./pages/Help";
import { useEffect } from "react";
import ReactGA from "react-ga";
import ScrollToTop from "./componets/ScrollToTop";
// import PrivateRoutes from './utils/PrivateRoutes';

function App() {
  const globalSnackbar = useSelector((state) => state?.helper?.globalSnackbar);
  const dispatch = useDispatch();
  const onCloseToast = () => {
    dispatch(editHelper({ globalSnackbar: {} }));
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Routes>
        <Route path="/login/otp" element={<OTPForm />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/apparel" element={<Apparel />} />
        <Route path="/footwear" element={<Footwear />} />
        <Route path="/accessories" element={<Accessories />} />
        <Route path="/food-beverages" element={<Food />} />
        <Route path="/pet-care" element={<PetCare />} />
        <Route path="/service" element={<Service />} />
        <Route path="/beauty-wellness" element={<Beauty />} />
        <Route path="/about" element={<About />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/blog-detail/:id" element={<BlogDetails />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/term-condition" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/help-support" element={<Help />} />

        {/* route by himanshu */}
        <Route path="/category/:id" element={<Category />} />
        <Route path="/profile/:id" element={<ProductDetails />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Snackbar
        open={globalSnackbar?.open}
        autoHideDuration={1000}
        onClose={onCloseToast}
      >
        <Alert severity={globalSnackbar?.type} sx={{ width: "100%" }}>
          {globalSnackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "../css/blog.css";
import Images from "../images";
import "../css/offers.css";
import Register from "../componets/register";
import Footer from "../componets/footer";
import "../js/main.js";
import Header from "../componets/navbar";
import { apiCall, imageUrl, isArrayWithValues } from "../js/helper";
import { ImageList, ImageListItem, Stack } from "@mui/material";

export default function Offers() {
  const [offersArray, setOffersArray] = useState([]);
  const getBanner = async () => {
    let res = await apiCall({
      endpoint: "banner/getAllBanner",
    });
    if (isArrayWithValues(res?.data) && !res?.error) setOffersArray(res?.data);
    console.log(res);
  };

  useEffect(() => {
    getBanner();
    return () => {};
  }, []);

  return (
    <>
      <Header />
      <body className="blog1">
        <div className="line"></div>
        <div className="category">
          <div className="cat-child1">
            <h3 className="h3Style1">Offers</h3>
          </div>
          {/* <div className="cat-child">
            <div
              class="category-dropdown filter-offers"
              style={{
                marginRight: "80px",
                paddingTop: "5px",
                width: "max-content",
              }}
            >
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Filter
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="/clothing">
                    Filter1
                  </a>
                  <a class="dropdown-item" href="/footwear">
                    Footwear
                  </a>
                  <a class="dropdown-item" href="/accessories">
                    Accessories
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {isArrayWithValues(offersArray) ? (
          <>
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={2}
              my={2}
              mx={10}
            >
              {offersArray.map((item) => {
                return (
                  <img
                    alt={"offer banner"}
                    src={`${imageUrl()}${item.bannerImage}`}
                    style={{
                      height: 250,
                      width: 250,
                      borderRadius: 8,
                      objectFit: "contain",
                    }}
                  />
                );
              })}
            </Stack>
            {/* <ImageList cols={3} rowHeight={150} sx={{ mx: 30 }} gap={4}>
              {offersArray.map((item) => {
                return (
                  <ImageListItem key={item.id} sx={{ m: 1.5, borderRadius: 8 }}>
                    <img
                      srcSet={`${imageUrl()}${
                        item.bannerImage
                      }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${imageUrl()}${
                        item.bannerImage
                      }?w=164&h=164&fit=crop&auto=format`}
                      alt={item.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                );
              })}
            </ImageList> */}
          </>
        ) : (
          <div className="offers">
            <center>
              {" "}
              <h3
                style={{
                  color: "#fff",
                  padding: "50px",
                  fontFamily: "Gotham",
                  marginRight: "80px",
                }}
              >
                Stay Tuned!!! <br /> Deal Hunters….!!! Homegrown offers are
                about to drop.
              </h3>
            </center>
          </div>
        )}
        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/css/main.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./store.js";
import { ThemeProvider, createTheme } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga4";
import ScrollToTop from "./componets/ScrollToTop";
const MEASUREMENT_ID = "G-X34HDRBBNK";
ReactGA.initialize(MEASUREMENT_ID);
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
  title: window.location.pathname,
});
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#f66b12",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 32, // Adjust the border radius as needed
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Gotham",
        },
      },
    },
  },
  typography: {
    fontFamily: "Gotham",
  },
});
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ScrollToTop />
            <App />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
);

reportWebVitals();

import React from 'react';
import '../css/offers.css';
import '../css/category.css';
import Images from '../images';
import '../css/blog.css';
import Register from '../componets/register';
import Footer from '../componets/footer';
import Header from '../componets/navbar';


export default function Footwear() {
  return (
    <>
      <Header />
      <body className="category-body">
        <div className="line"></div>
        <div className='category'>
          <div className="cat-child1">
            <h3 className='h3Style1' style={{ color: "#E6DCC6" }}>
              Footwear
            </h3>
          </div>
          <div className="cat-child">
            <div class="category-dropdown" style={{ marginRight: "80px" }}>
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Filter
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="/clothing">Filter1 </a>
                  <a class="dropdown-item" href="/footwear">Footwear</a>
                  <a class="dropdown-item" href="/accessories">Accessories</a>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* product Card start here  */}
        <div className="products-category">
          <div className="products-catlog">
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
            <div class="main-container">
              <img src={Images.Footwear} class="main-image1" />
              <img src={Images.Logo} class="rounded-image1" />
              <div class="rectangular-image" >
                <div className="product-name">
                  <p className='text-style-prod'>Brand Name : XYZ</p>
                  <p className='text-style-prod' >CATEGORIES:  <span className='span-class'>Footwear</span> <span className='span-class'>Apparel</span></p>
                  <p className='text-style-prod' >About the brand</p>
                </div>
                <hr className='prod-hr' />
                <center>
                  <img className='images-style' src={Images.India} alt="" />
                  <img className='images-style' src={Images.Recycle} alt="" />
                  <img className='images-style' src={Images.Rupee} alt="" />
                  <img className='images-style' src={Images.Comunnity} alt="" />
                </center>
              </div>
            </div>
          </div>
        </div>
        {/* product Card ends here  */}


        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>

  )
}

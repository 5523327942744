import axios from "axios";
import { category } from "../componets/navbar";
import store from "../store";

function isArrayWithValues(value) {
  // Check if the value is an array
  if (Array.isArray(value)) {
    // Check if the array has non-empty elements
    return value.length > 0;
  }
  return false; // Return false for non-array values
}

const getUrl = () => {
  // if (isDev()) return "http://unknownbazaar.in/temp/api";
  return "https://api.unknownbazaar.com/api";
};

const imageUrl = () => {
  // if (isDev()) return "http://unknownbazaar.in/temp/";
  return "https://api.unknownbazaar.com/";
};
// const getUrl = () => {
//   if (isDev()) return "http://unknownbazaar.in/temp/api";
//   return "https://unknownbazaar.in/temp/api";
// };
// const imageUrl = () => {
//   if (isDev()) return "http://unknownbazaar.in/temp/";
//   return "https://unknownbazaar.in/temp/";
// };
function shortenString(inputString, maxLength) {
  if (!inputString) return "";
  if (inputString.length <= maxLength) {
    // If the input string is already shorter than the desired length, return it as is.
    return inputString;
  }

  // Split the input string into words.
  const words = inputString.split(" ");

  let shortenedString = "";
  let currentLength = 0;

  for (const word of words) {
    // Check if adding the current word would exceed the maximum length.
    if (currentLength + word.length + shortenedString.length > maxLength) {
      break; // Stop if it exceeds the maximum length.
    }

    // Add the word and a space to the shortened string.
    shortenedString += (shortenedString.length === 0 ? "" : " ") + word;
    currentLength += word.length;
  }

  // Add an ellipsis (...) to indicate that the string has been shortened.
  if (shortenedString.length < inputString.length) {
    shortenedString += "...";
  }

  return shortenedString;
}
const getCategoryNameById = (id) => {
  return Object.values(category)
    ?.map((o) => o?.options)
    ?.flat()
    ?.find((o) => o?.id == id);
};
const getToken = () => {
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiU3VuIEF1ZyAyNyAyMDIzIDE1OjE4OjQ5IEdNVCswNTMwIChJbmRpYSBTdGFuZGFyZCBUaW1lKSIsInVzZXIiOm51bGwsImlhdCI6MTY5MzEyOTcyOX0.aiZohIKVCKK6EBUWlEb_CujrgMCaZubBCRp_g1BXyAM";
  const state = store.getState();
  console.log(state);

  return state?.user?.user?.token || false;
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiVGh1IFNlcCAxNCAyMDIzIDIzOjUyOjUyIEdNVCswNTMwIChJbmRpYSBTdGFuZGFyZCBUaW1lKSIsInVzZXIiOiIyNjEyMzQ1Njc4OTAiLCJpYXQiOjE2OTQ3MTU3NzJ9.-G41ErglUQuRzc5sa5NL80cYc52DCEB21h8fORpThw8";
};

const getUserId = () => {
  const state = store.getState();
  return state?.user?.user?.id;
};
const getAxiosError = (error) => {
  return error?.message || "";
};
const endpointList = {
  blogs: "blog/getAllBlog",
  blogById: "blog/getBlogById",
  brandByCategory: `brand/getBrandByCategory`,
  login: "auth/loginUser",
  brandByBrandId: "brand/getBrandByBrandId",
  wishlist: "wishlist/getAllWishlistedBrand",
};

const apiCall = async ({ endpoint = "", method = "GET", data, params }) => {
  try {
    const url = getUrl();
    const token = getToken();
    let { data: res } = await axios({
      url: `${url}/${endpoint}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method,
      data,
      params,
    });
    return res;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
function getRelativeTime(dateString) {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return timeDifferenceInSeconds + " sec ago";
  } else if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return minutesAgo + " min ago";
  } else if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return hoursAgo + " hours ago";
  } else {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return daysAgo + " day(s) ago";
  }
}

function isObjectWithProperties(value) {
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    // Check if the object has at least one property
    return Object.keys(value).length > 0;
  }
  return false; // Return false for non-object values
}
function formatDate(date, format) {
  const monthFullNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthsNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours24 < 12 ? "AM" : "PM"; // Determine AM/PM

  const formatObj = {
    YYYY: year,
    MM: month < 10 ? "0" + month : month,
    M: month,
    MMMM: monthFullNames[month - 1],
    MMM: monthsNames[month - 1],
    DD: day < 10 ? "0" + day : day,
    D: day,
    HH: hours24 < 10 ? "0" + hours24 : hours24,
    H: hours24,
    hh: hours12 < 10 ? "0" + hours12 : hours12,
    h: hours12,
    mm: minutes < 10 ? "0" + minutes : minutes,
    m: minutes,
    ss: seconds < 10 ? "0" + seconds : seconds,
    s: seconds,
    A: ampm,
    P: ampm.toLowerCase(),
  };

  return format.replace(
    /YYYY|MMMM|MMM|MM|M|DD|D|HH|H|hh|h|mm|m|ss|s|A|P/g,
    (match) => formatObj[match]
  );
}
function capitalizeWords(str) {
  // Split the string into an array of words
  if (!str || typeof str !== "string") return "";
  const words = str?.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return "";
    } else {
      return word[0].toUpperCase() + word.slice(1);
    }
  });

  // Join the capitalized words back into a string
  return capitalizedWords.join(" ");
}
function isDev() {
  return process.env.NODE_ENV === "development";
}
const smsApiKey =
  "XFmSBlTRH7qLdMawKvAEctCUyb2hOQDZeu9ioI8r0YgfGPsVN6asAz6KF8fblSQVMgdYkRmxHEN9p0oZ";
function getRandomInt(length) {
  if (length <= 0) {
    throw new Error("Length must be greater than 0.");
  }

  const min = 10 ** (length - 1); // Minimum value for the given length
  const max = 10 ** length - 1; // Maximum value for the given length
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function convertToTitleCase(inputString) {
  if (!inputString) return "";
  // Split the input string on hyphens or underscores
  const words = inputString?.split(/[-_]/);

  // Capitalize the first letter of each word and join them with a space
  const titleCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return titleCaseString;
}
function convertToCapitailzeCase(inputString) {
  if (!inputString) return "";
  // Split the input string on hyphens or underscores
  const words = inputString?.split(/[,]/);

  // Capitalize the first letter of each word and join them with a space
  const titleCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(", ");

  return titleCaseString;
}
function formatString(inputStr) {
  if (!inputStr) return "";
  const words = inputStr?.split(",");

  if (words.length === 1) {
    return words[0];
  }
  // else if (words.length === 2) {
  //   return `${words[0].charAt(0).toUpperCase() + words[0].slice(1)} / ${words[1].charAt(0).toUpperCase() + words[1].slice(1)}`;
  // }
  else {
    const formattedWords = words
      .slice(0, -1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const lastWord =
      words[words.length - 1].charAt(0).toUpperCase() +
      words[words.length - 1].slice(1);
    formattedWords.push(lastWord);
    return formattedWords.join(" / ")?.replace("Cod", "Cash on delivery");
  }
}
function extractNumbersFromString(inputString) {
  if (!inputString) return "";
  // Use a regular expression to find all numbers in the string
  const numbers = inputString.match(/\d+/g);

  // Check if any numbers were found
  if (numbers) {
    // Convert the matched numbers from strings to integers
    return numbers.map(Number);
  } else {
    // Return an empty array if no numbers were found
    return [];
  }
}
function getFileType(url) {
  // Extract the file extension from the URL
  const fileExtension = url.split(".").pop().toLowerCase();

  // Define an array of common video file extensions
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "mkv",
    "webm",
    "flv",
    "wmv",
    "mpeg",
    "3gp",
  ];

  // Define an array of common image file extensions
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "webp",
    "svg",
    "tiff",
    "ico",
  ];

  // Check if the file extension is in the video extensions array
  if (videoExtensions.includes(fileExtension)) {
    return "video";
  }

  // Check if the file extension is in the image extensions array
  if (imageExtensions.includes(fileExtension)) {
    return "image";
  }

  // If the extension doesn't match any known video or image extensions, you can
  // also check the MIME type, but that requires an asynchronous request to the server.
  // You can use the fetch API to do that.

  // If none of the checks match, return 'unknown' or handle it as needed.
  return "unknown";
}
const getSellerSite = () => {
  return "https://seller.unknownbazaar.com";
};

function truncateString(inputString, maxLength) {
  if (!inputString) return "";
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    // Use substring to extract the portion of the string up to maxLength - 3 (for the ellipsis)
    return inputString.substring(0, maxLength - 3) + "...";
  }
}

export {
  isArrayWithValues,
  getUrl,
  apiCall,
  endpointList,
  getRelativeTime,
  getAxiosError,
  imageUrl,
  isObjectWithProperties,
  getCategoryNameById,
  formatDate,
  capitalizeWords,
  getToken,
  isDev,
  smsApiKey,
  getRandomInt,
  shortenString,
  convertToTitleCase,
  formatString,
  extractNumbersFromString,
  convertToCapitailzeCase,
  getFileType,
  getSellerSite,
  truncateString,
  getUserId,
};

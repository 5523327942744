import React from "react";
import "../css/blog-details.css";
import Images from "../images";
import Footer from "../componets/footer";
import Register from "../componets/register";
import Header from "../componets/navbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  apiCall,
  endpointList,
  getRelativeTime,
  imageUrl,
  isArrayWithValues,
  isObjectWithProperties,
} from "../js/helper";
import { getAllBlogs } from "../redux/actions/helperActions";
import { useState } from "react";

export default function BlogDetails() {
  const { id } = useParams();
  const allReduxBlogs = useSelector((state) => state?.helper?.allBlogs);
  const [blog, setBlog] = useState({});
  const dispatch = useDispatch();
  let imageDomain = imageUrl();

  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxBlogs)) dispatch(getAllBlogs({}));

  //   return () => {};
  // }, []);

  const setUpBlog = async () => {
    // if (!isArrayWithValues(allReduxBlogs)) return;

    let blogObject = allReduxBlogs?.find((o) => o?.id == id);

    if (blogObject) setBlog(blogObject);
    else {
      try {
        let blogRes = await apiCall({
          endpoint: endpointList?.blogById,
          method: "POST",
          data: {
            id,
          },
        });
        if (!blogRes?.error && isObjectWithProperties(blogRes?.data))
          setBlog(blogRes?.data);
      } catch (error) {
        console.log("blog error", error);
      }
    }
  };
  useEffect(() => {
    setUpBlog();

    return () => {};
  }, [id, allReduxBlogs]);

  return (
    <>
      <Header />
      <body className="blog-details">
        <div className="line"></div>
        <div className="blog-info">
          <h2 className="blog-heading">
            {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe quas
            quia minus magni fugiat fugit doloremque repellendus cupiditate quod
            ullam! */}
            {blog?.title}
          </h2>
          <img
            className="blog-image1"
            src={`${imageDomain}${blog?.blogImage}`}
            alt=""
          />
          <h5 className="time">
            {" "}
            {blog?.uploadedOn ? getRelativeTime(blog?.uploadedOn) : null}{" "}
            {/* <span>| 4 minutes read</span> */}
          </h5>
          <p
            className="full-blog"
            dangerouslySetInnerHTML={{ __html: blog?.description }}
          >
            {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore,
            quaerat sequi. Unde assumenda quasi, sint illo deserunt quidem,
            blanditiis deleniti dolore sed eaque maiores eum incidunt est
            officia. Unde, sunt cumque sit doloribus, facilis quos ducimus ut
            corporis omnis, necessitatibus facere maiores. Optio eveniet
            voluptate dignissimos deleniti facere esse magni, soluta nobis, a ex
            ut veniam nemo similique reiciendis repellendus culpa ab libero
            accusantium ullam. Necessitatibus perferendis totam, consectetur
            adipisci repudiandae libero! Facere accusamus molestiae ut debitis
            provident iure, ab quod velit, deleniti distinctio nisi quam sit
            quibusdam cupiditate. Necessitatibus modi cum, aspernatur eaque
            cumque est facere nobis at quis provident accusantium, iusto eum
            mollitia hic pariatur repellat id unde voluptas quo! Consequatur vel
            consequuntur autem est ad molestiae porro. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Nulla ad dolorum aperiam */}

            {/* {blog?.description?.replace(/<[^>]*>/g, "")} */}
          </p>
        </div>
        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>
  );
}

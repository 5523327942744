const initialState = {};
export const EDIt_USER = "EDIt_USER";
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case EDIt_USER: {
      return {
        ...state,

        ...(action.payload || {}),
      };
    }
    default:
      return state;
  }
}

import React from "react";
import { Link } from "react-router-dom";
import Images from "../images/index.js";
import { getSellerSite } from "../js/helper.js";

export default function Footer() {
  return (
    <>
      {/* <!-- FOOTER START --> */}
      <div class="footer">
        <div class="contain">
          <div class="col">
            <ul>
              <Link className="footer-text" to="/about">
                <li>About us</li>
              </Link>
              <Link className="footer-text" to="/term-condition">
                <li>Terms and conditions</li>
              </Link>
              <Link className="footer-text" to="/privacy-policy">
                <li>Privacy policy </li>
              </Link>
              <Link className="footer-text" to="/faq">
                <li>FAQ's</li>
              </Link>
              <Link className="footer-text" to="/help-support">
                <li className="footer-text">Help & support</li>
              </Link>
              <Link
                className="footer-text"
                to={getSellerSite()}
                target="_blank"
              >
                <li>List your brand</li>
              </Link>
            </ul>
          </div>
          <div class="col">
            <ul>
              <li className="footer-text">
                <Link
                  to="/blog"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Blogs
                </Link>
              </li>
              <Link className="footer-text" to="/offers">
                <li>Offers</li>
              </Link>
            </ul>
          </div>
          <div className="col">
            <h3 className="social-text">Follow Us!</h3>
            <div class="col social">
              <ul>
                <li>
                  <Link
                    to="https://instagram.com/unknownbazaar?igshid=NzZlODBkYWE4Ng=="
                    target="_blank"
                  >
                    <img
                      className="social-icon-footer"
                      src={Images.Instgram}
                      width="32"
                      style={{ width: "32px" }}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=61551947854832&mibextid=9R9pXO">
                    <img
                      className="social-icon-footer"
                      src={Images.facebook}
                      width="32"
                      style={{ width: "26px" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <h4
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                Coming Soon
              </h4>
              <div className="app-download">
                <div className="app-icon">
                  <img
                    src={Images.Playstore}
                    // width="32"
                    style={{ width: "120px", paddingRight: "15px" }}
                  />
                  <img
                    src={Images.Appstore}
                    // width="32"
                    style={{ width: "120px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      {/* <!-- END OF FOOTER --> */}
    </>
  );
}

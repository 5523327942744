import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { AppStore, AppStoreIcon, PlayStore, PlayStoreIcon } from "../svg";

const RegisterPopup = ({ openPopup, handleClose }) => {
  console.log(openPopup);
  return (
    <Dialog
      open={openPopup}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Unlock the Full Experience!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enjoy all our features, exclusive content, offers and much more on our
          mobile app.
        </DialogContentText>
        {/* <Typography variant="h6" sx={{ mt: 2 }}>
          📱 Download Now and Transform Your Experience!
        </Typography> */}
        <Typography sx={{ mt: 3 }}>Coming Soon :</Typography>
        <Stack
          direction={"row"}
          // justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          <Stack>
            <Stack direction={"row"} gap={1}>
              <PlayStoreIcon height={24} width={24} />
              {/* <Typography>PlayStore</Typography> */}
            </Stack>
            {/* <Typography>Coming Soon</Typography> */}
          </Stack>
          <Stack>
            <Stack direction={"row"} gap={1}>
              <AppStoreIcon height={35} width={35} />
              {/* <Typography>PlayStore</Typography> */}
            </Stack>
            {/* <Typography>Coming Soon</Typography> */}
          </Stack>
          {/* <PlayStore />
          <AppStore /> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        {/* <Button
      // onClick={handleClose}
      >
        Disagree
      </Button> */}
        <Button
          onClick={handleClose}
          // autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterPopup;

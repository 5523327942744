import React from "react";
import { getSellerSite } from "../js/helper";

export default function Register() {
  return (
    <>
      <div className="register">
        <center>
          <h4 className="regsiter-text">Register your homegrown brand here!</h4>
          <p className="register-text1">
            Showcase the power of homegrown.
            <br /> Embrace the power of Desi, be a part of UB clan & enjoy a
            sustainable growth.
          </p>
          <button
            className="register-button register-text1"
            onClick={() => window.open(getSellerSite(), "_blank")}
          >
            Be a part of the UB Clan
          </button>
        </center>
      </div>
    </>
  );
}

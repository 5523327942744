import React, { useState, useEffect, useRef } from 'react';
import '../css/create-profile.css';
import Images from '../images';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Login } from '../features/auth/authAction';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';


export default function CreateProfile() {
  const inputRef = useRef(null);
  const [image, setImage] = useState("");

  const handleImageClick = () => {
    inputRef.current.click();
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setImage(file)

  }




  const { loading, error, success, } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();


  useEffect(() => {

    // redirect user to login/OTP page if registration was successful
    if (success) navigate("/");


  }, [navigate, success])


  const submitForm = (data) => {

    dispatch(Login(data));
  };

  return (
    <>
      <div className="logo-style1">
        <img src={Images.Logo} alt="unknown-bazaar" height={100} width={200} />
      </div>
      <div className="center-box">
        <div className="container-form-profile">
          <h2 className="login-title">Create Profile</h2>
          <form className="login-form" onSubmit={handleSubmit(submitForm)}>
            {error && <Alert severity="error" >
              <AlertTitle>Error</AlertTitle>
              error
              Please check your information — <strong>check it out!</strong>
            </Alert>}
            <center> <label htmlFor="image">Choose Image</label></center>
            <div onClick={handleImageClick} >
              {
                image ? <center><img className='image-upload-after' src={URL.createObjectURL(image)} alt="user" /></center> : <center> <img className='image-upload' src={Images.uploadImage} alt="user-image" /></center>
              }
              <input type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }} />

            </div>
            <div className="input-box">
              <input
                className='input-box-1'
                type="text"
                id="name"
                name="name"
                // onChange={handlePhoneNumberChange}
                placeholder="Enter Your Name"
                {...register('name')}
                maxLength="200"
                autoFocus
                required
              />
              <input
                className='input-box-1'
                type="date"
                id="dob"
                name="dob"
                // onChange={handlePhoneNumberChange}
                placeholder="Enter your Date of Birth"
                {...register('dob')}
                maxLength="10"
                autoFocus
                required
              />
            </div>

            <input
              type="textArea"
              id="interest"
              name="interest"
              // onChange={handlePhoneNumberChange}
              placeholder="Tell us about your interest"
              {...register('interest')}
              // maxLength="10"
              autoFocus
              required
            />
            <button className="btn btn--form" type="submit" disabled={loading}>
              {loading ? <CircularProgress color="success" /> : 'Create Profile'}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

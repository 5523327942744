import { createSlice } from "@reduxjs/toolkit";
import { Login, OtpVerify } from "./authAction";
import Cookies from "js-cookie";

let OTP = Cookies.get('OTPStatus');
// let session_id = Cookies.get('Details');


const initialState = {
    loading: false,
    OTP: {},
    // userInfo : null,
    error: null,
    success: false,
    otpSuccess : false,
    phone: null,
    // session_id: null

}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            Cookies.remove('OTPStatus') // deletes token from storage
            state.loading = false
            state.userInfo = null
            state.OTP = null
            state.error = null
        },

        setCredentials: (state, { payload }) => {
            state.userInfo = payload
        },
    },
    extraReducers: {

        // otp verify
        [OtpVerify.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [OtpVerify.fulfilled]: (state, { payload }) => {
            state.loading = false
            // state.userInfo = payload
            state.otpSuccess = true
            // state.OTP = payload.OTP
        },
        [OtpVerify.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // phone number take
        [Login.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [Login.fulfilled]: (state, { payload }) => {

            state.loading = false
            state.success = true // registration successful
            // state.session_id = Cookies.get("Details")
        },
        [Login.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

    
    }
})

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;


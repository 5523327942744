const initialState = {
  blogs: [],
};
export const EDIt_HELPER = "EDIt_HELPER";
export default function helperReducer(state = initialState, action) {
  switch (action.type) {
    case EDIt_HELPER: {
      return {
        ...state,

        ...(action.payload || {}),
      };
    }

    default:
      return state;
  }
}

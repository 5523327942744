import React, { useState } from "react";
import "../css/blog.css";
import Images from "../images";
import Register from "../componets/register";
import Footer from "../componets/footer";
import "../js/main.js";
import { Link } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import Header from "../componets/navbar";
import { useEffect } from "react";
import {
  apiCall,
  endpointList,
  getRelativeTime,
  imageUrl,
  isArrayWithValues,
} from "../js/helper";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../redux/actions/helperActions";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [fetching, setFetching] = useState(false);
  const allReduxBlogs = useSelector((state) => state?.helper?.allBlogs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(allReduxBlogs))
      dispatch(getAllBlogs({ setLoading: setFetching }));

    return () => {};
  }, []);

  useEffect(() => {
    if (isArrayWithValues(allReduxBlogs)) setBlogs(allReduxBlogs);

    return () => {};
  }, [allReduxBlogs]);

  return (
    <>
      <Header />
      <body className="blog1">
        <div className="line"></div>
        <div className="category-blog">
          <div className="cat-child1">
            <h3 className="h3Style1">Blog</h3>
          </div>
          
        </div>
        <div className="blogs">
          <div className="blogs-tiles">
            {blogs?.map((blog, index) => {
              let { title, blogImage, uploadedOn, id } = blog;
              let imageDomain = imageUrl();
              return (
                <div className="blogs-tiles-child" key={id}>
                  <Link className="link-blog-tiles" to={`/blog-detail/${id}`}>
                    <img
                      className="blogs-image"
                      src={`${imageDomain}${blogImage}`}
                      alt=""
                    />
                    <h3 className="blogs-heading">{title}</h3>
                    <p className="blogs-date">
                      {getRelativeTime(uploadedOn)}
                      {/* |<span>5 min read</span>{" "} */}
                    </p>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>
  );
}

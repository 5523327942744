import React from "react";
import Images from "../images";
import "../css/profile.css";
import { Link } from "react-router-dom";
import Register from "../componets/register";
import Footer from "../componets/footer";
import Header from "../componets/navbar";
import { useSelector } from "react-redux";
import { formatDate } from "../js/helper";

export default function Profile() {
  const user = useSelector((state) => state?.user?.user);
  let { firstName, lastName, email, phone, dob } = user || {};
  return (
    <>
      <Header />
      <body className="profile">
        <div className="line"></div>
        {/* <hr className='horizontal-line' /> */}
        <div className="profile-parent">
          <div className="profile-info">
            <div className="profile-child">
              {/* <center>
                <h2 className="buddies-num">20</h2>
                <h3 className="buddies">Buddies</h3>
              </center> */}
            </div>
            <div className="profile-child">
              <center>
                <img
                  className="profile-image"
                  // src={Images.Person}
                  src={
                    "https://imgs.search.brave.com/HYWzW_u62rwqcCiSeS0ZXFOm48DScY8Z70UIusbvOyg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAzLzMyLzU5LzY1/LzM2MF9GXzMzMjU5/NjUzNV9sQWRMaGY2/S3piVzZQV1hCV2VJ/RlRvdlRpaTFkcmti/VC5qcGc"
                  }
                  height={120}
                  width={120}
                  alt=""
                />
                <p className="name">
                  {/* Claudia Alves */}
                  {firstName} {lastName}
                </p>
                {/* <p className="interest">Intrest - discribe about yourself</p> */}
              </center>
            </div>
          </div>
          <div className="info">
            <p className="info-text">Email id : {email}</p>
            <p className="info-text">Mobile Number : {phone}</p>
            <p className="info-text">
              Date of birth :{" "}
              {dob ? formatDate(new Date(dob), "DD/MM/YYYY") : null}
            </p>
          </div>
        </div>

        <div className="nav-info">
          <div className="nav-info-child" style={{ paddingTop: "10px" }}>
            <center>
              <img src={Images.Home} alt="" height={50} />
              <p className="info-text">100% Hand Picked Homegrown brands</p>
            </center>
          </div>
          <div className="nav-info-child">
            <center>
              <img
                src={Images.Heart}
                alt=""
                height={60}
                style={{ paddingTop: "20px" }}
              />
              <Link className="link-info-text" to="/wishlist">
                <p style={{ textDecoration: "none" }} className="info-text">
                  Wishlist
                </p>
              </Link>
            </center>
          </div>
          <div className="nav-info-child">
            <center>
              <img src={Images.Verified} alt="" height={60} />
              <p className="info-text">Brands Verified By Unknown Bazaar</p>
            </center>
          </div>
        </div>

        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
      </body>
    </>
  );
}

import React, { useState } from "react";
import "../css/wishlist.css";
import Images from "../images/index";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Register from "../componets/register";
import Footer from "../componets/footer";
import Header from "../componets/navbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryNameById, isArrayWithValues } from "../js/helper";
import { getAllWishlist } from "../redux/actions/helperActions";
import { Box, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Fragment } from "react";
import { AppStore, AppStoreIcon, PlayStore, PlayStoreIcon } from "../svg";

export default function Wishlist() {
  const [open, setOpen] = useState(false);
  const [isHeartClicked, setIsHeartClicked] = useState(true);
  const reduxWishlist = useSelector((state) => state?.helper?.wishlist);
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!isArrayWithValues(reduxWishlist)) dispatch(getAllWishlist());
    return () => { };
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxWishlist)) setBrands(reduxWishlist);

    return () => { };
  }, [reduxWishlist]);


  // pop up
  // Function to open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Use setTimeout to open the dialog after 5 seconds
    const timer = setTimeout(() => {
      handleOpen();
    }, 5000);

    // Clear the timer when the component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      <Header />
      <body className="wishlist">
        <div className="line"></div>
        <center style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <h3 className="h3Style">Wishlist</h3>
        </center>
        <div className="whislist-product">
          <div className="products">
            <Stack alignItems={"center"} gap={2} direction={"row"}>
              {brands?.map((obj) => {
                const { id, brandName, category1Id, category2Id } = obj || {};
                return (
                  <Fragment key={id}>
                    <Box onClick={() => navigate(`/profile/${id}`)}>
                      <div className="image-card">
                        <img
                          src={Images.Clothes}
                          alt="Main Image"
                          className="main-image"
                        />
                        <div className="icons-container">
                          <div
                            className="heart-icon-container"
                            onClick={handleHeartClick}
                          >
                            <FontAwesomeIcon
                              icon={faHeart}
                              size="lg"
                              className={
                                isHeartClicked ? "heart-filled" : "heart-empty"
                              }
                            />
                          </div>
                        </div>
                        <div className="rounded-image">
                          <img
                            src={Images.Logo}
                            alt="Rounded Image"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      </div>
                      <h5 className="brand-name">{brandName}</h5>
                      <p className="brand-cat">
                        {getCategoryNameById(category1Id)?.label}
                        {" ,"}
                        {getCategoryNameById(category2Id)?.label}
                      </p>
                    </Box>
                  </Fragment>
                );
              })}
            </Stack>
          </div>
        </div>
        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />
        {/* Footer ends  */}
        {/* The dialog */}
        <Dialog open={open} onClose={handleClose}
          PaperProps={{
            style: {
              maxWidth: '80%', // Change this value to your desired width
            },
          }} >
          <DialogTitle>Unlock the Full Experience!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enjoy all our features, exclusive content, offers and much more on our mobile app.
              <p> Coming soon </p>
              <div>
                <Stack
                  direction={"row"}
                  // justifyContent={"center"}
                  alignItems={"center"}
                  gap={3}
                >
                  <Stack>
                    <Stack direction={"row"} gap={1}>
                      <PlayStoreIcon height={60} width={60} />
                      {/* <Typography>PlayStore</Typography> */}
                    </Stack>
                    {/* <Typography>Coming Soon</Typography> */}
                  </Stack>
                  <Stack>
                    <Stack direction={"row"} gap={1}>
                      <AppStoreIcon height={80} width={80} />
                      {/* <Typography>PlayStore</Typography> */}
                    </Stack>
                    {/* <Typography>Coming Soon</Typography> */}
                  </Stack>
                  {/* <PlayStore />
          <AppStore /> */}
                </Stack>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </body>
    </>
  );
}

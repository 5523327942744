import React from 'react';
import Header from "../componets/navbar";
import "../css/static-pages.css";
import Footer from "../componets/footer.jsx";

const Privacy = () => {
    return (
        <>
            <Header />
            <body className="profile">
                <div className="line"></div>

                {/* // contain */}
                <div className="contain-body">
                    <h2 className="h3Style" style={{ textAlign: "center", paddingBottom: "15px" }}>
                        Privacy Policy
                    </h2>
                    <div className="contain-text">
                        <p className="static-body-text" >
                            Welcome to Unknown Bazaar! This privacy policy outlines how we collect, use, disclose, and protect the personal information of our users. By accessing or using our website [www.unknownbazaar.com] (the "Website") and our services, you consent to the practices described in this policy.
                            <br />
                            <br />
                            Information We Collect
                            <br />
                            a. Personal Information: When Users and Sellers create an account, place an order, or interact with the Platform, we may collect certain personal information, including but not limited to:
                            <br />
                            Name <br />
                            Email address <br />
                            Postal address <br />
                            Phone number <br />
                            Payment information (e.g., credit card details) <br />
                            Profile information (e.g., username, profile picture) <br />
                            b. Non-Personal Information: We may also collect non-personal information, including but not limited to:
                            <br />
                            Browser type and version <br />
                            Device type and operating system <br />
                            IP address <br />
                            Website usage data (e.g., pages visited, time spent on the site) <br />
                            c. Cookies and Similar Technologies: The Platform uses cookies and similar technologies to collect and store certain information. Users and Sellers can manage their cookie preferences through their browser settings.
                            <br />
                            <br />
                            How We Use Your Information <br />
                            a. Provide Services: We use personal information to deliver the services Users and Sellers request process orders, and facilitate transactions on the Platform.
                            <br />
                            b. Communication: We may use your contact information to communicate with you about your account, orders, promotions, and updates related to the Platform. Users and Sellers can opt out of marketing communications at any time.
                            <br />
                            c. Improve our Services: Non-personal information helps us understand how Users and Sellers interact with the Platform, allowing us to enhance and improve our services and user experience.
                            <br />
                            d. Personalization: We may use collected data to personalize the experience on the Platform, such as displaying relevant products, services, or recommendations.
                            <br />
                            e. Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal processes.
                            <br />
                            <br />
                            How We Share Your Information
                            <br />
                            a. Third-Party Service Providers: We may share personal information with trusted third-party service providers who assist us in operating the Platform and providing services to Users and Sellers (e.g., payment processors, shipping companies).
                            <br />
                            b. Seller Information: When Users place an order or interact with a Seller on the Platform, we may share relevant personal information with the Seller to facilitate the transaction and order fulfillment.
                            <br />
                            c. Business Transfers: If Unknown Bazaar undergoes a merger, acquisition, or sale of all or part of our assets, personal information may be transferred as part of the transaction.
                            <br />
                            d. Legal Requirements: We may disclose information if required by law or to protect our rights, privacy, safety, or property, or that of others.
                            <br />
                            <br />
                            Data Security
                            a. We implement reasonable security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                            <br />
                            User Rights
                            a. Users and Sellers have the right to access, update, and delete their personal information stored by Unknown Bazaar. Please contact us at <span style={{ color: "#f66b12" }} >[connect@unknownbazaar.com]</span> to exercise these rights.
                            <br />
                            <br />
                            Third-Party Links and Content <br />
                            a. The Platform may contain links to third-party websites or content. Unknown Bazaar is not responsible for the privacy practices or content of these third-party websites. Users and Sellers access third-party websites at their own risk and are encouraged to review the privacy policies of such websites.
                            <br />
                            <br />
                            Children's Privacy <br />
                            a. Unknown Bazaar does not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove the information promptly.
                            <br />
                            <br />
                            Changes to this Privacy Policy <br />
                            a. We may update this privacy policy from time to time. The most recent version will be posted on the Platform, and any changes will be effective when published.
                            <br />
                            <br />
                            Contact Us <br />
                            a. If you have any questions, concerns, or requests regarding this privacy policy or our data practices, please contact us at <span style={{ color: "#f66b12" }} >[connect@unknownbazaar.com]</span>.
                            <br />
                        </p>
                    </div>
                  
                </div>
                <hr className="footer-hr-line" />
                {/* Footer start here  */}
                <Footer />
                {/* footer ends here  */}
            </body>
        </>
    )
}

export default Privacy

import React from "react";
import "../css/dropdown.css";
import { Link, useNavigate } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { EDIt_USER } from "../redux/reducers/userReducers";
import { setGlobalToast } from "../redux/actions/helperActions";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { LogoutOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { useState } from "react";

export default function Dropdown() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = useSelector((state) => state?.user?.user);
  const onLogout = () => {
    console.log("onClick");
    dispatch({
      type: EDIt_USER,
      payload: {
        user: null,
      },
    });
    dispatch(
      setGlobalToast({
        open: true,
        message: "Log out Successfully !",
      })
    );
    handleClose();
  };

  const onProfileClick = () => {
    if (user) navigate(`/profile`);
    else navigate(`/login`);
  };

  return (
    <>
      <Button
        startIcon={
          //   <IoPersonOutline color="white" size={20} className="icon-top-left" />
          <PersonOutlineOutlined />
        }
        sx={{ color: "#fff" }}
        variant="text"
        onClick={handleClick}
      >
        Profile
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onProfileClick}>
          {" "}
          <ListItemIcon>
            <PersonOutlineOutlined fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
        {user ? (
          <MenuItem onClick={onLogout}>
            {" "}
            <ListItemIcon>
              <LogoutOutlined fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        ) : null}
      </Menu>
      {/* <div className="custom-container">
        <IoPersonOutline color="white" size={20} className="icon-top-left" />
        <select class="custom-select">
          <option selected className="select-option">
            <IoPersonOutline
              color="white"
              // size={24}
              className="icon-top-left"
            />{" "}
            Profile
          </option>
          <option className="select-option" onClick={onLogout}>
            {" "}
            <FiLogOut /> Logout
          </option>
        </select>
      </div> */}
    </>
  );
}

import React from "react";
import Images from "../images";
import {
  apiCall,
  capitalizeWords,
  convertToTitleCase,
  convertToCapitailzeCase,
  formatDate,
  formatString,
  getToken,
} from "../js/helper";
import { useNavigate } from "react-router-dom";
function formatOperationalHours(data) {
  if (
    !data?.customerServiceOperationalHour ||
    !data?.customerServiceOperationalDay
  )
    return "";
  const days = {
    0: "Mon",
    1: "Tue",
    2: "Wed",
    3: "Thu",
    4: "Fri",
    5: "Sat",
    6: "Sun",
  };

  const [startHour, endHour] = data?.customerServiceOperationalHour?.split("-");
  const daysOfWeek = data?.customerServiceOperationalDay
    ?.split("")
    ?.map((day) => days[day])
    ?.join(" - ");

  return `${
    data?.customerServiceOperationalDay
      ? `Day ${days[0]}  - ${
          days?.[data?.customerServiceOperationalDay] || days?.[6]
        } `
      : null
  } Time - ${startHour}am to ${endHour}pm`;
}
// console.log(startHour)
// console.log(endHour)
export default function ProductInfo({ product, setOenPopup }) {
  console.log(product);
  let {
    activeDate,
    deliveryLocation,
    deliveryDuration,
    totalCustomerServed,
    shippingFeeStatus,
    returnExchangeStatus,
    customerServiceOperationalHour,
    customerServiceOperationalDay,
    yoe,
    minmaxValue,
    refundStatus,
    paymentMode,
    filterCategory,
    responseTime,
  } = product || {};
  let token = getToken();
  const navigate = useNavigate();

  return (
    <>
      <div className="product-info-tiles">
        <h4 className="product-info-text">Must Know Facts </h4>
        <div
          className={`product-info-box ${token ? "" : " "}`} // Aamir removing blurred-div - use blurred-div to hide
          onClick={() => !token && navigate("/login")}
          // onClick={() => !token && setOenPopup && setOenPopup(true)}
        >
          {/* <!-- Box Container --> */}
          <div class="box-container-prod">
            {/* <!-- Box 0 --> */}
            <div class="box-info">
              <img class="icon-box" src={Images.Launch} alt="Icon" />
              <h5 className="box-info-text">Launch</h5>
              <p className="box-info-text1">
                {/* {activeDate ? formatDate(new Date(activeDate), "YYYY") : null} */}
                {yoe}
              </p>
            </div>

            {/* <!-- Box 2 --> */}
            <div class="box-info">
              <img class="icon-box" src={Images.Location} alt="Icon" />
              <h5 className="box-info-text">Delivery Location</h5>
              <p className="box-info-text1">
                {/* Mumbai */}
                {deliveryLocation}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Customers} alt="Icon" />
              <h5 className="box-info-text">Satisfied customers </h5>
              <p className="box-info-text1">
                {/* 1000 */}
                {totalCustomerServed}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Price} alt="Icon" />
              <h5 className="box-info-text"> Price Range (INR)</h5>
              <p className="box-info-text1">
                {/* Min : 500 Max: 3000 */}
                {minmaxValue}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Delievery} alt="Icon" />
              <h5 className="box-info-text">Delivery Duration </h5>
              <p className="box-info-text1">
                {/* Min Days: 1 Max: 10Day */}
                {deliveryDuration}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.shipping} alt="Icon" />
              <h5 className="box-info-text">Shipping Fee</h5>
              <p className="box-info-text1">
                {/* Yes */}
                {capitalizeWords(shippingFeeStatus)}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Return} alt="Icon" />
              <h5 className="box-info-text">Return / Exchange Acceptable</h5>
              <p className="box-info-text1">
                {/* Exchange */}
                {convertToTitleCase(returnExchangeStatus)}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Payments} alt="Icon" />
              <h5 className="box-info-text">Mode of payments </h5>
              <p className="box-info-text1">{formatString(paymentMode)}</p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.CustomerServices} alt="Icon" />
              <h5 className="box-info-text"> Customer Service</h5>
              <p className="box-info-text1">
                {/* Day- Mon - Fri Time - 9am to 6pm */}
                {"Day : " +
                  customerServiceOperationalDay +
                  " Time : " +
                  customerServiceOperationalHour}
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Refund} alt="Icon" />
              <h5 className="box-info-text">Refund</h5>
              <p className="box-info-text1">
                {convertToTitleCase(refundStatus)} *
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.CustomerServices} alt="Icon" />
              <h5 className="box-info-text">Response Time</h5>
              <p className="box-info-text1">
                {convertToTitleCase(responseTime)} *
              </p>
            </div>
            <div class="box-info">
              <img class="icon-box" src={Images.Return} alt="Icon" />
              <h5 className="box-info-text">Product Fit For</h5>
              <p className="box-info-text1">
                {convertToCapitailzeCase(filterCategory)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { Fragment, useEffect, useState, useRef } from "react";
import Images from "../images";
import "../css/product-details.css";
import { AiOutlineLink } from "react-icons/ai";
import { FiThumbsUp } from "react-icons/fi";
import ProductInfo from "../componets/product-info";
import Review from "../componets/review";
import Register from "../componets/register";
import Footer from "../componets/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Header from "../componets/navbar";
import { useParams } from "react-router-dom";
import {
  apiCall,
  endpointList,
  getCategoryNameById,
  getFileType,
  imageUrl,
  isObjectWithProperties,
} from "../js/helper";
import { useSelector } from "react-redux";
import {
  Badge,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import RegisterPopup from "../componets/RegisterPopup";
import Carousel from "react-material-ui-carousel";

export default function ProductDetails() {
  const param = useParams();
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [brandInfo, setBrandInfo] = useState({});
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const collections = useSelector((state) => state?.helper?.collections);
  const setUpProfile = async () => {
    let obj = collections?.find((o) => o?.sellerIdd == id);
    if (isObjectWithProperties(obj)) setBrandInfo(obj);
    else {
      try {
        let res = await apiCall({
          endpoint: endpointList.brandByBrandId,
          data: {
            brandId: id,
          },
          method: "POST",
        });

        if (!res?.error && isObjectWithProperties(res?.data)) {
          setBrandInfo(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    let parasArray = param?.id?.split("_");
    setId(parasArray?.[0]);
    return () => {};
  }, [param?.id]);

  useEffect(() => {
    setUpProfile();
    return () => {};
  }, [id]);

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };

  let {
    brandName,
    category1Id,
    category2Id,
    logoImage,
    websiteLink,
    instagramLink,
    whatsappLink,
    aboutBrand,
    images,
    offer,
  } = brandInfo || {};
  const theme = useTheme();
  const [openPopup, setOenPopup] = useState(false);
  const handleClose = () => {
    setOenPopup(false);
  };

  const onLinkClick = async (id) => {
    await apiCall({
      endpoint: "seller/updateTraffic",
      method: "POST",
      data: {
        sellerId: brandInfo?.id,
      },
    });
  };
  // console.log(aboutBrand)
  // console.log(productFit);
  // console.log(serviceResponse)
  // Create a ref to reference the video element
  const videoRef = useRef(null);
  // Track video playback state

  // Handle video play/pause
  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  };

  // Attach handlers to video ref
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onplay = handleVideoPlay;
      videoRef.current.onpause = handleVideoPause;
    }
  }, []);

  const handleSlideChange = () => {
    if (isVideoPlaying && videoRef.current) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  // Carousel options
  const carouselOptions = {
    autoPlay: !isVideoPlaying,
    // ...other options
  };

  const carouselRef = useRef();

  // // Function to control the video
  // const controlVideo = (action) => {
  //   const video = videoRef.current;

  //   if (video) {
  //     switch (action) {
  //       case "play":
  //         video.play();
  //         setVideoPlaying(true); // Update videoPlaying state
  //         break;
  //       case "pause":
  //         video.pause();
  //         setVideoPlaying(false); // Update videoPlaying state
  //         break;
  //       // You can add more cases for other actions
  //       default:
  //         break;
  //     }
  //   }
  // };

  const isIpad = useMediaQuery((theme) => theme?.breakpoints?.down("md"));
  function ensureHttps(url) {
    // Check if the URL starts with 'http://' or 'https://'
    if (!/^https?:\/\//i.test(url)) {
      // If not, add 'https://' to the beginning of the URL
      url = "https://" + url;
    }
    return url;
  }
  return (
    <>
      <Header />
      <body className="product-details">
        <div className="line"></div>
        <div className="container1">
          <div className="image-column">
            {/* <Carousel>

               </Carousel> */}
            <Carousel
              navButtonsAlwaysVisible={true}
              sx={{ minHeight: 300 }}
              {...carouselOptions}
              onChange={handleSlideChange}
            >
              {images?.map((o) => {
                return (
                  <Fragment key={o?.imagePath}>
                    <Badge
                      sx={{
                        ".MuiBadge-badge": {
                          top: "50px",
                          right: "30px",
                        },
                      }}
                      // badgeContent={
                      //   // <div
                      //   //   className="heart-icon-container bookmark-product"
                      //   //   onClick={handleHeartClick}
                      //   // >
                      //   //   <FontAwesomeIcon
                      //   //     icon={faHeart}
                      //   //     className={
                      //   //       isHeartClicked
                      //   //         ? "heart-filled fa-2x "
                      //   //         : "heart-empty fa-2x "
                      //   //     }
                      //   //   />
                      //   // </div>
                      // }
                    >
                      {getFileType(o?.imagePath) === "image" ? (
                        <img
                          src={`${imageUrl()}${o?.imagePath}`}
                          // src={Images.Clothes}
                          alt="Image"
                          className="main-image-card"
                        />
                      ) : (
                        <>
                          <video
                            className="main-image-card"
                            ref={videoRef}
                            onPlay={handleVideoPlay}
                            onPause={handleVideoPause}
                            controls
                            style={{ padding: "40px" }}
                            // loop
                          >
                            <source
                              src={`${imageUrl()}${o?.imagePath}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </>
                      )}
                    </Badge>

                    {/* <div className="icons-container1 ">
                    <div
                      className="heart-icon-container bookmark-product"
                      onClick={handleHeartClick}
                    >
                      <FontAwesomeIcon
                        icon={faHeart}
                        className={
                          isHeartClicked
                            ? "heart-filled fa-2x "
                            : "heart-empty fa-2x "
                        }
                      />
                    </div>
                  </div> */}
                  </Fragment>
                );
              })}
            </Carousel>
            {/* <img src={Images.Clothes} alt="Image" className="main-image-card" /> */}
            {/* <div className="icons-container1">
              <div className="heart-icon-container" onClick={handleHeartClick}>
                <FontAwesomeIcon
                  icon={faHeart}
                  className={
                    isHeartClicked
                      ? "heart-filled fa-2x "
                      : "heart-empty fa-2x "
                  }
                />
              </div>
            </div> */}
          </div>
          <div className="text-column">
            <div className="text-content">
              <h3 className="brand-name-prod">Brand Name - {brandName}</h3>
              <p
                className="category-product-tag"
                style={{ lineHeight: "20px" }}
              >
                <span className="cat-prod">CATEGORIES: </span>
                <div className="category-tag-style">
                  {getCategoryNameById(category1Id)?.label && (
                    <span
                      className="cat-tag"
                      style={
                        isIpad
                          ? { paddingTop: "10px", marginRight: "5px" }
                          : { marginRight: "5px" }
                      }
                    >
                      {" "}
                      {getCategoryNameById(category1Id)?.label}
                    </span>
                    // <Chip
                    //   label={getCategoryNameById(category1Id)?.label}
                    //   size="small"
                    //   sx={{
                    //     backgroundColor: "rgb(255, 255, 255)",
                    //     color: "#f66b12",
                    //     textTransform: "uppercase",
                    //     fontFamily: "bodyText",
                    //   }}
                    // />
                  )}
                  {getCategoryNameById(category2Id)?.label && (
                    <span
                      className="cat-tag"
                      style={isIpad ? { paddingTop: "10px" } : {}}
                    >
                      {getCategoryNameById(category2Id)?.label}
                    </span>
                    // <Chip
                    //   label={getCategoryNameById(category2Id)?.label}
                    //   size="small"
                    //   sx={{
                    //     backgroundColor: "rgb(255, 255, 255)",
                    //     color: "#f66b12",
                    //     textTransform: "uppercase",
                    //     fontFamily: "bodyText",
                    //   }}
                    // />
                  )}
                </div>
              </p>
              <p className="cat-prod1">Check out the brand:</p>
              <div className="social-icon-prod">
                {websiteLink && (
                  <AiOutlineLink
                    className="link-icon"
                    size={40}
                    onClick={() => {
                      onLinkClick();
                      websiteLink &&
                        window.open(ensureHttps(websiteLink), "_blank");
                    }}
                  />
                )}
                {instagramLink && (
                  <img
                    className="image-icon-prod"
                    src={Images.Instgram}
                    alt=""
                    onClick={() =>
                      instagramLink && window.open(instagramLink, "_blank")
                    }
                  />
                )}
                {whatsappLink && (
                  <img
                    className="image-icon-prod"
                    src={Images.Whatsapp}
                    alt=""
                    onClick={() =>
                      whatsappLink && window.open(whatsappLink, "_blank")
                    }
                  />
                )}
              </div>
              <div className="brand-text">
                <p className="cat-prod1">Know the brand : </p>
                <p className="cat-prod-details">
                  <Typography
                    sx={{
                      fontFamily: "bodyText",
                      fontSize: "15px",
                      fontWeight: null,
                      lineHeight: "22px",
                    }}
                    dangerouslySetInnerHTML={{ __html: aboutBrand }}
                  >
                    {/* {aboutBrand} */}
                  </Typography>
                  {/* Zara was started by Amancio Ortega in 1975. His first shop was
                  in central A Coruña, in Galicia, Spain, where the company is
                  still based. He initially called it Zorba after the classic
                  1964 film Zorba the Greek, but after learning there was a bar
                  with the same name two blocks away */}
                </p>
              </div>
              {offer && (
                <div className="offers-line">
                  <p className="offer-text">Offer:</p>
                  <span className="product-offer">{offer}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* product info  */}
        <ProductInfo product={brandInfo} setOenPopup={setOenPopup} />
        {/* product info  */}

        {/* review  */}
        <Review
          product={brandInfo}
          setOenPopup={setOenPopup}
          setProduct={setBrandInfo}
        />
        {/* review  */}

        {/* register templet */}
        <Register />
        {/* register templet */}

        {/* Footer start  */}
        <Footer />

        {/* Footer ends  */}

        <RegisterPopup handleClose={handleClose} openPopup={openPopup} />
      </body>
    </>
  );
}

import Hero from "./hero.png";
import MobileHero from "./mobile-hero.png";
import Clothes from "./clothes.png";
import Footwear from "./footwear.png";
import Accessory from "./Accessories.png";
import Food from "./food.png";
import Pet from "./pet.png";
import Services from "./service.png";
import Beauty from "./beauty.png";
import Blog1 from "./blog-banner-1.png";
import Blog2 from "./blog-banner-2.png";
import Logo from "./logo.png";
import IMG from "./img1.png";
import Shop from "./shop.png";
import Verified from "./verified.png";
import Mobile from "./mobile.png";
import Verify from "./verify.png";
import Instgram from "./instagram.png";
import Playstore from "./playstore.png";
import Appstore from "./appstore.png";
import Person from "./person.jpg";
import Heart from "./heart.png";
import Home from "./home.png";
import Offer1 from "./offer1.png";
import Offer2 from "./offer2.png";
import Offer3 from "./offer3.png";
import Comunnity from "./community.png";
import India from "./india.png";
import Recycle from "./recycle.png";
import Rupee from "./rupee.png";
import Whatsapp from "./whatsapp.png";
import Launch from "./Launch.png";
import CustomerServices from "./Customer-Service.png";
import Customers from "./customers.png";
import Delievery from "./Delivery.png";
import Location from "./Location.png";
import Payments from "./payments.png";
import Price from "./Price.png";
import Refund from "./Refund.png";
import Return from "./Return.png";
import shipping from "./Shipping.png";
import uploadImage from "./upload.png";
import User from "./user.png";
import facebook from "./facebook.png";
import UB1 from "./UB1.png";
import UB2 from "./UB2.png";
import UB3 from "./UB3.png";
import ResponseTime from "./product-res.png";
import PlaceholderImage from "./product-placeholder.png";

const Images = {
  Hero,
  MobileHero,
  Clothes,
  Footwear,
  Accessory,
  Food,
  Pet,
  Services,
  Beauty,
  Logo,
  IMG,
  Shop,
  Verified,
  Mobile,
  Verify,
  Instgram,
  Playstore,
  Appstore,
  Person,
  Heart,
  Home,
  Offer1,
  Offer2,
  Offer3,
  Comunnity,
  India,
  Recycle,
  Rupee,
  Whatsapp,
  Launch,
  CustomerServices,
  Customers,
  Delievery,
  Location,
  Payments,
  Price,
  Refund,
  Return,
  shipping,
  Blog1,
  Blog2,
  uploadImage,
  User,
  facebook,
  UB1,
  UB2,
  UB3,
  ResponseTime,
  PlaceholderImage,
};

export default Images;
